import { makeStyles } from '../../core/utils/theme'
import { Box, Tooltip } from '@mui/material'
import { isEndUserView } from '../../core/utils/main'
import useSettings from '../../hooks/useSettings'
import { error } from '../../core/services/alerts'
import useApp from '../../hooks/useApp'
import { observer } from 'mobx-react'

const useStyles = makeStyles()((theme) => ({
  questionInner: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '1rem',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: '2rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '1.5rem',
      marginTop: 0,
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '1rem',
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      gap: '1rem',
      marginTop: 0,
    },
  },
  question: {
    display: 'inline-block',
    maxWidth: '300px',
    height: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontWeight: '400',
    padding: '4px',
    color: '#119',
    '&:hover': {
      background: '#bbf',
      color: 'black',
    },

    '& > p': {
      margin: 0,
      textAlign: 'center',
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  },
}))

export const SampleQuestions = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const app = useApp()

  const handleOnClick = (question: string) => {
    if (app.isThinking) {
      error('Please wait until the previous question is answered or canceled.')
      return
    }
    if (app.isInitializing) {
      error('Please wait until tables are loaded')
      return
    }
    app.resetQuestion()
    app.setInput(question)
    app.ask()
  }

  return (
    <Box className="overflow-auto">
      <Box
        className={classes.questionInner}
        sx={
          isEndUserView()
            ? { display: 'flex', alignItems: 'center', gap: '1rem !important' }
            : { display: 'grid' }
        }
      >
        {settings.sampleQuestions?.length > 0 &&
          settings.sampleQuestions.map((item: { question: string }, i) => {
            // determine if the tooltip needs to be shown if the question length is greater than 101.
            // const showTooltip = item.question.length > 101;
            // disableHoverListener={!showTooltip} // PUT this back in tooltip to set the conditional rendering
            return (
              <Tooltip
                title={<p className="text-base">{item.question}</p>}
                arrow
                key={i}
                placement="left"
              >
                <Box
                  key={i}
                  className={classes.question}
                  id={`${i == 1 ? 'runSampleQuestion' : ''}`}
                  onClick={() => handleOnClick(item.question)}
                >
                  <p className="text-sm italic ">{item.question}</p>
                </Box>
              </Tooltip>
            )
          })}
      </Box>
    </Box>
  )
})
