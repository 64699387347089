import { FC, PropsWithChildren } from 'react'
import { makeStyles } from '../../core/utils/theme'
import useSettings from '../../hooks/useSettings'
import LoadingSplashScreen from '../Misc/LoadingSplashScreen'
// import { useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react'

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.default,
    height: '100%',
    width: '100%',
  },
}))

const PageLayout: FC<PropsWithChildren> = observer(({ children }) => {
  const { classes } = useStyles()
  const settings = useSettings()
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div className={classes.root}>
      {settings.awaitingState ? <LoadingSplashScreen text="Loading Dashboard" /> : children}
    </div>
  )
})

export default PageLayout
