import { FC, ReactNode } from 'react'
import { Box, Typography, Button, Stack } from '@mui/material'
import Modal from 'react-modal'

// icon
import CONTACTICON from '../../assets/Svgs/action/contact_support_24px.svg'

interface IProps {
  open: boolean
  onClose: () => void
  children?: ReactNode
}
const NeedMoreDataCakesModal: FC<IProps> = ({ open = false, onClose }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={
        'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10'
      }
      className={`static mx-4 max-h-full min-h-[230px] max-w-full border border-black bg-white px-6 py-4 md:max-w-[480px]`}
    >
      <Box pt={1} className="flex h-full flex-col">
        <Box className="flex-1 ">
          <Typography className="mb-4">You need to add more datacakes?</Typography>
          <Typography variant="body2" color={'#5B6B6F'} className="mb-4 leading-5">
            This is an advanced feature that is only available for premium accounts. Please contact
            us for further information.
          </Typography>
        </Box>
        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="text"
            onClick={() => {
              window.location.href = 'https://www.datacakes.ai/#contact'
            }}
            startIcon={<img src={CONTACTICON} />}
          >
            Contact us
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default NeedMoreDataCakesModal
