import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import { LoadingSpinner } from '../Misc/Loader'
import EditableLine from '../Misc/EditableLine'
import { to_html } from '../../core/utils/main'
import BackspaceIcon from '@mui/icons-material/Backspace'
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    // height:'100%',
    width: '100%',
    marginTop: '0.25rem',
    marginBottom: '0rem',
    background: 'rgba(256,256,256, 0.3)',
    position: 'relative',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem',
    // background: '#88888833',
    '& > div': {
      padding: '0.25rem',
      // fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
  thoughts: {
    width: '100%',
    maxWidth: '100%',
    fontSize: '13px',
    minHeight: '4rem',
    maxHeight: '100%',
    overflowX: 'clip',
    overflowY: 'auto',
    padding: '0 0.75rem 0.75rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  x: {
    marginLeft: '8px',
    opacity: '40%',
    cursor: 'pointer',
    '&:hover': {
      opacity: '100%',
    },
  },
}))

export const Thoughts: FC<{ submit: () => void }> = observer(({ submit }) => {
  const { classes } = useStyles()
  const app = useApp()
  const [clarification, setClarification] = useState<string>('')
  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const submitClarifications = () => {
    var clarifs = app.queryState.clarifications
    clarifs.push([app.queryState.clarification, clarification])
    app.setQueryStateAttributes({ clarifications: clarifs, clarification: '' })
    app.ask()
    setClarification('')
  }

  const retryOnError = () => {
    if (app.queryState.state.slice(0, 6) == 'error:')
      app.setQueryStateAttributes({ state: app.queryState.state.slice(6) })
    // app.queryState.state = app.queryState.state.slice(6)
    console.log(app.queryState)
    submit()
  }

  const dismissError = () => {
    app.setQueryStateAttributes({ error: '' })
    // app.queryState.error = ''

    if (app.queryState.state.slice(0, 6) == 'error:')
      app.setQueryStateAttributes({ state: app.queryState.state.slice(6) })
    // app.queryState.state = app.queryState.state.slice(6)
  }

  const clearSection = (section: string) => {
    app.setQueryStateAttributes({ state: 'edited' })
    console.log(hasChanged)
    setHasChanged(true)
    console.log(hasChanged)
    if (section == 'sources') {
      app.setQueryStateAttributes({ sources: null })
    } else if (section == 'functions') {
      app.setQueryStateAttributes({ functions: [] })
    } else if (section == 'clarification') {
      setClarification('')
      app.setQueryStateAttributes({
        clarification: '',
        clarifications: [],
        assumptions: [],
        plan: [],
        code: [],
      })
    } else if (section == 'assumptions') {
      app.setQueryStateAttributes({
        assumptions: [],
        plan: [],
        code: [],
      })
    } else if (section == 'plan') {
      app.setQueryStateAttributes({
        plan: [],
        code: [],
      })
    } else if (section == 'code') {
      app.setQueryStateAttributes({ code: [] })
    }
    console.log(hasChanged)
  }

  const resubmit = () => {
    app.setQueryStateAttributes({ state: 'resubmit' })
    setHasChanged(false)
    submit()
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Box className="flex text-sm">Cubie's Sketchpad</Box>

        {app.isThinking && (
          <Box
            className="cursor-pointer text-sm"
            onClick={() => {
              app.cancelRequestAction()
            }}
          >
            <DangerousOutlinedIcon />
          </Box>
        )}
        {!app.isThinking && (hasChanged || app.queryState.state.includes('error')) && (
          <Box
            className="cursor-pointer text-sm"
            onClick={() => {
              resubmit()
            }}
          >
            Resubmit
          </Box>
        )}
      </Box>
      {app.queryState.error && (
        <Box className="mx-8 flex justify-between border-4 border-red-500">
          <div className="flex-0 p-4">
            <b>Error:</b> {app.queryState.error}
          </div>
          <div className="flex-0 flex flex-col justify-between border-l-4 border-l-red-500 px-4 py-2">
            <div
              className="my-2 cursor-pointer border border-black px-2 text-center"
              onClick={() => {
                console.log(app.queryState)
              }}
            >
              Log
            </div>
            <div
              className="my-2 cursor-pointer border border-black px-2 text-center"
              onClick={() => retryOnError()}
            >
              Retry
            </div>
            <div
              className="my-2 cursor-pointer border border-black px-2 text-center"
              onClick={() => dismissError()}
            >
              Dismiss
            </div>
          </div>
        </Box>
      )}
      <Box className={classes.thoughts}>
        {app.queryState.sources && (Object.keys(app.queryState.sources) || []).length > 0 && (
          <Box>
            <b>Data Sources</b>
            {!app.isThinking && (
              <span
                className={classes.x}
                onClick={() => {
                  clearSection('sources')
                }}
              >
                <BackspaceIcon sx={{ height: '14px' }} />
              </span>
            )}
            <pre style={{ textWrap: 'pretty' }}>
              {app.queryState.sources &&
                Object.keys(app.queryState.sources).map((k, i) => (
                  <code key={i}>
                    <EditableLine
                      key={i}
                      isEditable={!app.isThinking}
                      line={`${k}: ${app.queryState.sources ? app.queryState.sources[k as keyof Object].join(', ') : '**Error**'}`}
                      onFinish={(l: string) => {
                        const idx = l.indexOf(':')
                        const kk = l.slice(0, idx)
                        const vv = l
                          .slice(idx + 1)
                          .split(',')
                          .map((x) => x.trim())
                        if (
                          app.queryState.sources &&
                          app.queryState.sources[kk as keyof Object] != vv
                        ) {
                          setHasChanged(true)
                          app.setQueryStateAttributes({
                            sources: { ...app.queryState.sources, [kk]: vv },
                          })
                        }
                      }}
                    />
                  </code>
                ))}
            </pre>
          </Box>
        )}
        {app.queryState.functions && app.queryState.functions.length > 0 && (
          <Box>
            <b>Code Library Functions</b>
            {!app.isThinking && (
              <span
                className={classes.x}
                onClick={() => {
                  clearSection('functions')
                }}
              >
                <BackspaceIcon sx={{ height: '14px' }} />
              </span>
            )}
            <pre style={{ textWrap: 'pretty' }}>
              <code>
                <EditableLine
                  isEditable={!app.isThinking}
                  line={app.queryState.functions.join(', ')}
                  onFinish={(l: string) => {
                    const newFuncs = l.split(',').map((x) => x.trim())
                    if (app.queryState.functions != newFuncs) {
                      setHasChanged(true)
                      app.setQueryStateAttributes({ functions: newFuncs })
                    }
                  }}
                />
              </code>
            </pre>
          </Box>
        )}
        {app.queryState.clarification.length > 0 && !app.isThinking && (
          <Box className={app.queryState.state == 'clarification' ? 'border border-black p-2' : ''}>
            <b>Clarification</b>
            {!app.isThinking && (
              <span
                className={classes.x}
                onClick={() => {
                  clearSection('clarification')
                }}
              >
                <BackspaceIcon sx={{ height: '14px' }} />
              </span>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: to_html(app.queryState.clarification.trim()) }}
            />
            <Box className="ml-2 mt-1 flex items-center justify-between">
              <textarea
                style={{ width: '80%', padding: '4px' }}
                rows={clarification.split('\n').length}
                value={clarification}
                placeholder=" cmd + &#x23CE; to submit"
                onChange={(e) => setClarification(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == 'Enter' && (e.metaKey || e.ctrlKey)) {
                    submitClarifications()
                  }
                }}
              />
              <Box
                className="cursor-pointer border border-black bg-white px-2 py-0.5"
                onClick={submitClarifications}
              >
                Submit
              </Box>
            </Box>
          </Box>
        )}
        <Box className={app.queryState.state == 'plan' ? 'border border-black p-2' : ''}>
          {app.queryState.assumptions.length > 0 && (
            <Box>
              <b>Assumptions</b>
              {!app.isThinking && (
                <span
                  className={classes.x}
                  onClick={() => {
                    clearSection('assumptions')
                  }}
                >
                  <BackspaceIcon sx={{ height: '14px' }} />
                </span>
              )}
              {app.queryState.assumptions.map((a: string, i: number) => (
                <Box key={i}>
                  {/* <span className="cursor-pointer inline" onClick={()=>app.queryState.assumptions.splice(i,i)}>-</span> */}
                  <EditableLine
                    key={i}
                    isEditable={!app.isThinking}
                    line={a}
                    onFinish={(l: string) => {
                      if (app.queryState.assumptions[i] != l) {
                        setHasChanged(true)
                        var assumps = app.queryState.assumptions
                        assumps[i] = l
                        app.setQueryStateAttributes({ assumptions: assumps })
                      }
                    }}
                  />
                </Box>
              ))}
              {/* <Box className="cursor-pointer" onClick={()=>{app.queryState.assumptions.push("* ...")}}>+</Box> */}
              {!app.isThinking && app.queryState.state == 'assumptions' && (
                <Box className="pt-2 text-right">
                  <span
                    className="cursor-pointer border border-black bg-white px-2 py-1"
                    onClick={() => {
                      submit()
                    }}
                  >
                    Approve
                  </span>
                </Box>
              )}
            </Box>
          )}
          {app.queryState.plan.length > 0 && (
            <Box className="pt-2">
              <b>Plan</b>
              {!app.isThinking && (
                <span
                  className={classes.x}
                  onClick={() => {
                    clearSection('plan')
                  }}
                >
                  <BackspaceIcon sx={{ height: '14px' }} />
                </span>
              )}
              {app.queryState.plan.map((a: string, i: number) => (
                <Box key={i}>
                  {/* <span className="cursor-pointer inline" onClick={()=>app.queryState.plan.splice(i,i)}>-</span> */}
                  <EditableLine
                    key={i}
                    isEditable={!app.isThinking}
                    line={a}
                    onFinish={(l: string) => {
                      if (app.queryState.plan[i] != l) {
                        setHasChanged(true)
                        var plan = app.queryState.plan
                        plan[i] = l
                        app.setQueryStateAttributes({ plan: plan })
                      }
                    }}
                  />
                </Box>
              ))}
              {!app.isThinking && app.queryState.state == 'plan' && (
                <Box className="pt-2 text-right">
                  <span
                    className="cursor-pointer border border-black bg-white px-2 py-1"
                    onClick={() => {
                      submit()
                    }}
                  >
                    Approve
                  </span>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {app.queryState.code.length > 0 && (
          <Box className={app.queryState.state == 'code' ? 'border border-black p-2' : ''}>
            <b>Code</b>
            {!app.isThinking && (
              <span
                className={classes.x}
                onClick={() => {
                  clearSection('code')
                }}
              >
                <BackspaceIcon sx={{ height: '14px' }} />
              </span>
            )}
            <pre>
              <code>
                {app.queryState.code.map((a: string, i: number) => (
                  <Box key={i}>
                    <EditableLine
                      key={i}
                      isEditable={!app.isThinking}
                      line={a}
                      onFinish={(l: string) => {
                        if (app.queryState.code[i] != l) {
                          setHasChanged(true)
                          var code = app.queryState.code
                          code[i] = l
                          app.setQueryStateAttributes({ code: code })
                        }
                      }}
                    />
                  </Box>
                ))}
              </code>
            </pre>
            {!app.isThinking && app.queryState.state == 'plan' && (
              <Box className="pt-2 text-right">
                <span
                  className="cursor-pointer border border-black bg-white px-2 py-1"
                  onClick={() => {
                    submit()
                  }}
                >
                  Approve
                </span>
              </Box>
            )}
          </Box>
        )}
        <Box className="left-0 right-0 top-2 flex h-[40px] justify-center">
          {app.isThinking && <LoadingSpinner className="ml-[10px] inline h-[40px]" />}
        </Box>
      </Box>
    </Box>
  )
})
