import { Box } from '@mui/material'
import ContentModal from './ContentModal'
import { observer } from 'mobx-react'
import { FC, useState } from 'react'

interface IProp {
  isOpen: boolean
  close: () => void
  onSubmit: (s: string) => void
}

const RevisionModal: FC<IProp> = observer(({ isOpen, close, onSubmit }) => {
  const [revision, setRevision] = useState<string>('')

  const submit = () => {
    close()
    if (revision.length > 3) {
      onSubmit(revision)
    }
  }

  return (
    <ContentModal open={isOpen} title="" onClose={() => close()}>
      <Box className="flex flex-col p-4">
        <Box className="mb-5">Ask Cubie to make a change to the graph...</Box>
        <textarea
          className="border border-black"
          style={{ padding: '8px' }}
          value={revision}
          onChange={(e) => {
            setRevision(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter' && (e.ctrlKey || e.metaKey)) submit()
          }}
        ></textarea>
        <Box className="mt-4 flex justify-end">
          <Box className="cursor-pointer border border-black px-2" onClick={submit}>
            Submit
          </Box>
        </Box>
      </Box>
    </ContentModal>
  )
})

export default RevisionModal
