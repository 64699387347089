import { observer } from 'mobx-react'
import { FC, useEffect, useState } from 'react'
import { APP_NAME } from '../../core/config/main'
// import useSettings from '../../hooks/useSettings'
// import useUser from '../../hooks/useUser'
import { makeStyles } from '../../core/utils/theme'
import { Box, Typography } from '@mui/material'
import Loader from '../Misc/Loader'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    display: 'grid',
    gridTemplateRows: '90px 55vh max-content',
    paddingBottom: '2rem',
  },
  innerContainer: {
    margin: '1.5rem 0',
    minHeight: '55vh',
    maxWidth: '72rem',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '2rem',
  },
  description: {
    fontWeight: 300,
  },
  loggedInContainer: {
    marginBottom: '1.25rem',
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2rem',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  loaderContainer: {
    height: '30px',
  },
  userEmail: {
    color: theme.palette.text.primary,
  },
  authContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    [theme.breakpoints.down(1921)]: {
      marginTop: '-20px',
    },
  },
  authProviderContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    padding: '20px 0',
    [theme.breakpoints.down(1921)]: {
      padding: '0px 0',
      marginBottom: '10px',
    },
  },
  authBtn: {
    display: 'flex',
    borderWidth: '0 !important',
    padding: '0',
  },
  nextBtn: {
    marginTop: '2rem',
    alignSelf: 'flex-start',
  },
  datasetContainer: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3rem',

    [theme.breakpoints.down(1921)]: {
      marginTop: '0rem',
    },
  },
  dataset: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '140px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '72px',
    },

    [theme.breakpoints.down(1921)]: {
      transform: 'scale(0.8)',
    },
  },
  datasetIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '96px',
    height: '96px',
    background: '#EFEFEF',
    [theme.breakpoints.down('md')]: {
      width: '72px',
      padding: '1rem',
      height: '72px',
    },
  },
  datasetTitle: {
    marginTop: '0.5rem',
    color: theme.palette.text.disabled,
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%', // Ensure the container takes the full width
    display: 'flex',
    justifyContent: 'space-between', // Spread out buttons
    marginTop: 'auto', // Pushes the button container to the bottom
  },
  proceedButton: {
    flex: 1,
    backgroundColor: theme.palette.primary.main, // Or any color that stands out
    color: '#000000', // Usually, a text on a button is white for better contrast
    fontWeight: 'bold', // Makes the text bold
    border: '1px solid #000',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, // Darker shade for hover state
      color: '#ffffff', // Keep text color white on hover for contrast
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground, // Style for disabled state
      color: theme.palette.action.disabled, // Text color for disabled state
    },
  },
}))

interface IProps {}
const DataSourceManageBigQuery: FC<IProps> = observer(() => {
  // const user = useUser()
  // const settings = useSettings()
  const { classes } = useStyles()
  const [loading] = useState(false)

  useEffect(() => {}, [])

  return (
    <Box className={`${classes.root}`}>
      <Box className={`${classes.innerContainer}`}>
        <Typography variant="body1" className={classes.description}>
          You can connect {APP_NAME} to any BigQuery datasets in your Google account. Note that you
          must have the necessary permissions to create queries for your datasets.
        </Typography>
        <Box className={classes.loaderContainer}>
          {loading && <Loader className={classes.loader} />}
        </Box>
        <Box className={classes.authContainer}>
          {/* Show the customer ID picker if the user is authenticated and Google Ads customer ID is not selected */}
        </Box>
        {/* )} */}
      </Box>
    </Box>
  )
})

export default DataSourceManageBigQuery
