import { FC, useEffect, useState, MouseEvent } from 'react'
import PageLayout from '../Layouts/PageLayout'
import theme, { makeStyles } from '../../core/utils/theme'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import useUser from '../../hooks/useUser'
import useSettings from '../../hooks/useSettings'
import { useNavigate } from 'react-router-dom'
import { isEndUserView } from '../../core/utils/main'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { IconButton } from '@mui/material'
import LeftSidebar from '../LeftSidebar/LeftSidebar'
import AddIcon from '@mui/icons-material/Add'
import { LoadingSpinner } from '../Misc/Loader'
import SlideOverlay from './SlideOverlay'
import DashboardItem from './DashboardItem'
import { QueryState } from '../../stores/Query'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Tooltip } from 'react-tooltip'
import ShareIcon from '@mui/icons-material/Share'
import CopyToClipboard from 'react-copy-to-clipboard'
import { success } from '../../core/services/alerts'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    // maxWidth:"3000px"
  },
  dashboardContainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  sidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '325px',
    background: theme.palette.background.paper,
    boxShadow: '2px 0 5px rgba(0,0,0,0.2)',
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateX(-100%)',
    zIndex: 10,
  },
  sidebarOpen: {
    transform: 'translateX(0)',
  },
  menuButton: {
    position: 'fixed',
    top: 10,
    left: 10,
    zIndex: 5,
  },
  dashboardRoot: {
    alignItems: 'center',
    overflowY: 'auto',
    width: '100%',
  },
  dashboardTitle: {
    margin: '5px',
    padding: ' 0 20px',
    textAlign: 'center',
    position: 'relative',
  },
  dashboardTopControls: {
    height: '30px',
    display: 'flex',
    justifyContent: 'end',
    alignContent: 'center',
  },
  dashboardGridWrapper: {
    width: '650px',
    [theme.breakpoints.up(1650)]: {
      width: '1300px',
    },
    [theme.breakpoints.up(1980)]: {
      width: '1980px',
    },
    display: 'flex',
    justifyContent: 'center',
  },

  newItemBox: {
    margin: '20px',
    width: '608px',
    height: '608px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #999',
    color: '#999',
    cursor: 'pointer',
    '& .hiddenChild': {
      // visibility:'hidden'
    },
    '&:hover': {
      color: 'black',
      border: '1px solid black',
      '& .hiddenChild': {
        // visibility:'visible'
      },
    },
  },
  newItemBoxText: {},
  slideOverlayContainer: {
    height: '100%',
  },
  slideOverlayScreen: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    background: 'white',
    zIndex: '5',
    alignItems: 'center',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  slideContainer: {
    width: '90%',
    maxWidth: '1400px',
    aspectRatio: '16/10',
    margin: 'auto auto',
    background: '#def',
    border: '1px solid black',
    position: 'relative',
    display: 'flex',
    alignContent: 'center',
  },
  slideAnswerContainer: {
    width: '90%',
    maxWidth: '1200px',
    aspectRatio: '16/10',
    margin: 'auto auto',
    background: '#def',
    border: '1px solid black',
  },
}))

interface IProps {
  openOverlay: (items: QueryState[], i: number) => void
}

const DashboardItems: FC<IProps> = observer(({ openOverlay }) => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [isShareTooltipOpen, setIsShareTooltipOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!window.location.pathname.includes('dashboard'))
      navigate(isEndUserView() ? `/dashboard?cakeId=${app.cakeId}` : `/dashboard`)
  }, [app.cakeId])

  const handlers = {
    delete: async (query_id: string) => {
      const r = await app.deleteDashboardItem(app.cakeId, query_id)
      console.log('r', r)
      app.loadDashboard(app.cakeId)
    },
    reorder: (currentPosition: number, newPosition: number) => {
      if (
        newPosition < 1 ||
        newPosition > app.dashboard.length ||
        currentPosition === newPosition
      ) {
        return
      }
      const updatedItems = [...app.dashboard]
      const [movedItem] = updatedItems.splice(currentPosition - 1, 1)
      updatedItems.splice(newPosition - 1, 0, movedItem)
      app.setDashboard(updatedItems)
      app.saveDashboardOrder()
    },
    expand: (i: number) => {
      openOverlay(app.dashboard, i)
    },
  }

  const newDashboardItemClick = () => {
    console.log('newDBItemClick')
    app.prepareForQuestion(false)
    app.setInput('')
    navigate(isEndUserView() ? `/ask?cakeId=${app.cakeId}` : '/ask')
  }

  return (
    <Box className={classes.dashboardRoot}>
      {
        // Title Bar
        app.cakeId && (
          <Box className="relative mt-4 text-center">
            {app.isDashboardLoading && (
              <LoadingSpinner className="invisible mx-[10px] inline h-[20px]" />
            )}
            <span className="mt-[5px] inline h-[100%]">
              <Typography className="inline-block" variant="h6">
                <span
                  className={user.isAuthenticated() && !isEndUserView() ? 'cursor-pointer' : ''}
                  onClick={() => {
                    if (user.isAuthenticated() && !isEndUserView())
                      settings.setIsDatacakesModalOpen(true)
                  }}
                >
                  {app.cakeName || app.cakeId}
                </span>
              </Typography>
            </span>
            {app.isDashboardLoading && <LoadingSpinner className="mx-[10px] inline h-[20px]" />}
            <Box className="absolute right-6 top-2 h-6 w-6">
              <CopyToClipboard
                text={`${window.location.origin}/${window.location.href.includes('ask') ? 'ask' : 'dashboard'}?cakeId=${app.cakeId}`}
                onCopy={() => success('URL Copied!')}
              >
                <ShareIcon
                  data-tooltip-id="share-tooltip"
                  className="cursor-pointer opacity-40 hover:opacity-100"
                />
              </CopyToClipboard>
              <Tooltip
                id="share-tooltip"
                clickable={true}
                opacity={1}
                isOpen={isShareTooltipOpen}
                setIsOpen={setIsShareTooltipOpen}
              >
                Click to copy the URL to share this Datacake with others.
              </Tooltip>
            </Box>{' '}
          </Box>
        )
      }
      {
        // Main Dashboard Content
        <Box className="flex justify-center">
          <Box className={classes.dashboardGridWrapper}>
            <Grid container>
              {app.dashboard.map((item: QueryState, i: number) => (
                <DashboardItem
                  key={i}
                  item={item}
                  i={i}
                  numItems={app.dashboard.length}
                  handlers={handlers}
                />
              ))}
              <Box className="relative">
                <Grid
                  item
                  id="dashboardAddNewItem"
                  className={classes.newItemBox}
                  onClick={newDashboardItemClick}
                >
                  <div style={{ visibility: 'hidden' }}>New Dashboard Item</div>
                  <div>
                    <AddIcon fontSize="large" />
                  </div>
                  <div className="hiddenChild">New Dashboard Item</div>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Box>
      }
    </Box>
  )
})

const Dashboard: FC = observer(() => {
  const { classes, cx } = useStyles()
  const [firstRender, setFirstRender] = useState<boolean>(true)
  const [overlayItems, setOverlayItems] = useState<QueryState[] | null>(null)
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const app = useApp()

  const openOverlay = (dbItems: QueryState[], index: number) => {
    setOverlayItems(dbItems)
    setCurrentItemIndex(index)
  }

  const closeOverlay = () => {
    setOverlayItems(null)
  }

  const openSidebar = (e: MouseEvent) => {
    e.stopPropagation()
    app.setIsSidebarOpen(true)
  }

  const closeSidebar = () => {
    app.setIsSidebarOpen(false)
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      setTimeout(() => {
        closeSidebar()
      }, 1000)
    }
  }, [firstRender])

  return (
    <PageLayout>
      <Box className={classes.root}>
        {/* Sidebar Toggle Button */}
        {!isMobile && !app.isSidebarOpen && (
          <IconButton
            className={classes.menuButton}
            onClick={(e) => {
              openSidebar(e)
            }}
          >
            {/* {isSidebarOpen ? <CloseIcon /> : <MenuIcon />} */}
            <MenuIcon />
          </IconButton>
        )}

        {/* Sidebar */}
        <ClickAwayListener onClickAway={closeSidebar}>
          <Box
            component="div"
            className={cx(classes.sidebar, { [classes.sidebarOpen]: app.isSidebarOpen })}
          >
            <LeftSidebar />
          </Box>
        </ClickAwayListener>

        {/* Main Dashboard Content */}
        <Box className={classes.dashboardContainer}>
          <DashboardItems key={Math.random() * 10000} openOverlay={openOverlay} />
        </Box>

        {/* Slide Overlay */}
        {overlayItems && (
          <Box className={classes.slideOverlayContainer}>
            <SlideOverlay
              key={currentItemIndex}
              overlayItems={overlayItems}
              currentItemIndex={currentItemIndex}
              closeOverlay={closeOverlay}
              setCurrentItemIndex={setCurrentItemIndex}
            />
          </Box>
        )}
      </Box>
    </PageLayout>
  )
})

export default Dashboard
