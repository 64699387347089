import CloseIcon from '@mui/icons-material/Close'
import { FC, PropsWithChildren } from 'react'
import Modal from 'react-modal'
import theme, { makeStyles } from '../../core/utils/theme'
import { Box, IconButton, useMediaQuery } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  modalCloseBtn: {
    position: 'absolute',
    right: '3px',
    top: '3px',
    padding: '0',
    margin: '0',
    width: '1.75rem',
    height: '1.75rem',
    color: theme.palette.text.primary,
    zIndex: 3,
  },
  modalCloseIcon: {
    width: '1.75rem',
    height: '1.75rem',
  },
}))

const modalStyles: ReactModal.Styles = {
  overlay: {
    background: 'transparent',
    backdropFilter: 'blur(8px)',
    display: 'flex',
    zIndex: 11,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    WebkitBackdropFilter: 'blur(8px)',
  },
  content: {
    color: 'var(--qbd-color-a1)',
    border: '2px solid black',
    borderRadius: '0',
    padding: '0',
    width: '70%',
    position: 'static',
    maxHeight: 'calc(100% - 110px)',
    background: `linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 1) 100%)`,
    overflow: 'hidden',
  },
}

interface IProps {
  open: boolean
  title?: string
  onClose: () => void
  style?: Modal.Styles
  showCloseButton?: boolean
}
const ContentModal: FC<PropsWithChildren<IProps>> = ({
  children,
  open = false,
  onClose,
  title = '',
  style = modalStyles,
  showCloseButton = true,
}) => {
  const { classes } = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  if (modalStyles.content) {
    modalStyles.content.width = `${matches ? '95%' : '70%'}`
  }
  const newStyle: ReactModal.Styles = {
    overlay: modalStyles.overlay,
    content: { ...modalStyles.content, ...style.content },
  }
  return (
    <Modal
      isOpen={open}
      contentLabel={title}
      onRequestClose={onClose}
      style={newStyle}
      // Make sure to keep this value in sync with the animation duration in styles/base.css
      closeTimeoutMS={400}
      shouldCloseOnEsc={true}
    >
      <Box className="CONTENT_MODAL z-2 relative h-full">
        {showCloseButton && (
          <IconButton onClick={onClose} className={classes.modalCloseBtn} title="Close the Window">
            <CloseIcon className={classes.modalCloseIcon} />
          </IconButton>
        )}
        <Box className="FULL_CONTAINER flex h-full w-full flex-col">
          {title && <Box className="TITLE flex-0 py-2 text-center text-lg">{title}</Box>}
          <Box className="CONTENT_CONTAINER flex-1 overflow-auto">{children}</Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ContentModal
