import SettingsIcon from '@mui/icons-material/Settings'
import { Box, MenuItem, Select, Switch } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import useApp from '../../hooks/useApp'
import CloseIcon from '@mui/icons-material/Close'

const CubieSettings: FC = observer(() => {
  const [isSettingsTooltipOpen, setIsSettingsTooltipOpen] = useState<boolean>(false)
  const app = useApp()

  return (
    <Box>
      <SettingsIcon
        id="settings-icon"
        className="z-10 cursor-pointer opacity-40 hover:opacity-100"
        onClick={() => {
          setIsSettingsTooltipOpen(true)
        }}
      />
      <Tooltip
        anchorSelect="#settings-icon"
        openOnClick={true}
        clickable={true}
        opacity={1}
        globalCloseEvents={{
          escape: true,
          resize: false,
          scroll: false,
          clickOutsideAnchor: false,
        }}
        style={{ minWidth: '220px', zIndex: 20 }}
        isOpen={isSettingsTooltipOpen}
        setIsOpen={setIsSettingsTooltipOpen}
      >
        <Box
          className="absolute right-1 top-1 cursor-pointer text-sm"
          onClick={() => {
            setIsSettingsTooltipOpen(false)
          }}
        >
          <CloseIcon fontSize="small" />
        </Box>
        <Box className="align-center flex flex-col gap-2  text-[16px]">
          <Box className="flex-0 flex w-full justify-between gap-2 ">
            <Box className="flex flex-col justify-center">Auto continue</Box>
            <Box className="flex-1 text-center">
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: '#ccc', // Thumb color when OFF
                  },
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#fff', // Thumb color when ON
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#fff', // Track color when ON
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: '#ccc', // Track color when OFF
                  },
                }}
                checked={app.autoMode}
                onChange={() => app.setAutoMode(!app.autoMode)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </Box>
          <Box className="flex-0 flex w-full justify-between gap-8">
            <Box className="flex flex-col justify-center">LLM</Box>
            <Select
              className="min-w-[180px] bg-white"
              MenuProps={{
                PaperProps: {
                  sx: {
                    margin: '0',
                    backgroundColor: '#fff', // Set background color of the dropdown
                    color: 'black',
                    '& hover': {
                      backgroundColor: '#fff',
                    }, // Set text color for better visibility
                  },
                },
              }}
              size="small"
              value={app.model || 'default'}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                app.setModel(e.target.value)
              }}
            >
              <MenuItem value="default">default</MenuItem>
              <MenuItem value="gpt-4o">gpt-4o</MenuItem>
              <MenuItem value="o1-preview">o1-preview</MenuItem>
            </Select>
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
})

export default CubieSettings
