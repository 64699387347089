import { makeAutoObservable } from 'mobx'
import { error, success } from '../core/services/alerts'
import { startSSE, stopSSE } from '../core/services/messages'
import {
  post2api,
  fetchAnswer,
  fetchSources,
  flagAnswer,
  postToRunDashboardItem,
} from '../core/services/code_service'
import { IDataObject } from '../core/types/code_service/IDataObject'
import { getCakeIdFromUrl, getVersionFromUrl, randomString } from '../core/utils/main'
import { postModel } from '../core/services/source_service'
import { IDashboardItem } from '../core/types/code_service/IDashboardItem'
import { LOGIN_URL } from '../core/config/main'
import { IDatacake } from '../core/types/source_service/IDatacake'
import { IAnswerResponse } from '../core/types/code_service/IAnswerResponse'
import { QueryState } from './Query'
import cookies from 'js-cookie'

export class AppStore {
  public version: string = '0.2'
  public setVersion(v: string) {
    this.version = v
  }

  // ### Cubie State ##################################################################################

  public isSidebarOpen: boolean = true
  public setIsSidebarOpen(v: boolean) {
    this.isSidebarOpen = v
  }
  public model: string = cookies.get('model') || ''
  public setModel(value: string) {
    this.model = value
    cookies.set('model', this.model)
  }

  public cakeId: string
  public setCakeId(cakeId: string) {
    this.cakeId = cakeId
  }

  public cakeName: string = ''
  public setCakeName(cakeName: string) {
    this.cakeName = cakeName
  }

  public coreDataObjects: IDataObject[] = []
  public setCoreDataObjects(sources: IDataObject[]) {
    this.coreDataObjects = sources
  }

  public sampleQuestionVisible: boolean = true
  public setSampleQuestionVisible(visible: boolean) {
    this.sampleQuestionVisible = visible
    this.prepareForQuestion(false)
  }
  public autoMode: boolean = cookies.get('automode') == 'true'
  public setAutoMode(tf: boolean) {
    this.autoMode = tf
    cookies.set('automode', this.autoMode.toString())
  }

  private eventSource: EventSource | null = null

  public sessionId: string | null = null
  protected setSessionId(sessionId: string) {
    this.sessionId = sessionId
    console.log('Session ID', this.sessionId)
  }

  public traceId: string | null = null
  protected setTraceId(traceId: string) {
    this.traceId = traceId
    console.log('Trace ID', this.traceId)
  }

  public isInitializing: boolean = false
  public setIsInitializing(value: boolean) {
    this.isInitializing = value
  }

  public async updateSources(isEndUserView?: boolean) {
    if (!this.cakeId) {
      console.log('No cakeId, so no call to /initialize')
      return
    }
    if (this.isInitializing) {
      console.log('isInitializing true, so no new call to /initialize')
      return
    }
    try {
      this.setCoreDataObjects([])

      this.setIsInitializing(true)
      const response = await fetchSources(this.cakeId, isEndUserView)
      const sessionId = response.data.session_id

      if (sessionId === null) {
        error('session_id not initialized, please try loading the page again')
        return
      }

      this.setSessionId(sessionId)

      const originalSources = response.data.core || []
      if (originalSources.length === 0) {
        error('tables didn’t load, please wait a few minutes and try again.')
        return
      }
      this.setCoreDataObjects(originalSources)
    } catch (e: any) {
      if (e.response.status === 401 || e.response.status === 403) {
        this.setShowLoginModal(true)
        // return
      } else {
        this.handleError(e)
      }
    } finally {
      this.setIsInitializing(false)
    }
  }

  public resetCubie() {
    this.setCoreDataObjects([])
    this.setCakeId('')
    this.setCakeName('')
    this.sessionId = null
    this.queryState = new QueryState()
    this.setInput('')
    this.thoughtsText = ''
    this.setAnswer(null)
    this.dashboardItems = []
    this.chatHistory = []
  }

  public activateCake(cake: IDatacake) {
    this.resetCubie()
    this.setCakeId(cake.cake_id || '')
    this.updateSources()
    this.cakeName = cake.name || ''
    this.loadDashboard(this.cakeId)
  }

  // ### Question State ##################################################################################

  public queryState: QueryState = new QueryState()
  public setQueryStateAttributes(attrs: { [key: string]: any }) {
    this.queryState = { ...this.queryState, ...attrs }
  }

  public input: string = ''
  public setInput(value: string) {
    this.input = value
  }

  public isThinking: boolean = false
  public setIsThinking(value: boolean) {
    this.isThinking = value
  }

  public thoughtsText: string = ''
  public _thoughtSource: string | null = null
  public answer: IAnswerResponse | null = null
  public setAnswer(ans: IAnswerResponse | null) {
    this.answer = ans
  }
  public cancelRequest: AbortController | null = null
  private _chatHistory: string[] = []
  public _collapsed: boolean = true

  public isThoughtsOpenedInNewWindow: boolean = false

  public isNegativeFeedbackLeft: boolean = false
  public isPositiveFeedbackLeft: boolean = false

  set chatHistory(qa: string[]) {
    const maxLength = 10
    this._chatHistory = this._chatHistory.concat(qa)
    this._chatHistory.splice(0, this._chatHistory.length - maxLength)
  }
  get chatHistory(): string[] {
    return this._chatHistory
  }

  public async ask() {
    this.setSampleQuestionVisible(false)
    this.prepareForQuestion(true)
    if (this.queryState.question.length == 0) this.queryState.question = this.input
    const sseChannel = randomString()

    this.eventSource = startSSE(sseChannel, this.handleSSE.bind(this))

    let response = null

    this.cancelRequest = new AbortController()

    try {
      response = await fetchAnswer(
        this.queryState,
        [],
        sseChannel,
        this.sessionId,
        this.cakeId,
        this.model,
        this.cancelRequest.signal,
      )
    } catch (e: any) {
      this.queryState.state = 'error'
      this.queryState.error = e.message
      this.setIsThinking(false)
      this._collapsed = false

      stopSSE(this.eventSource)

      this.handleError(e)
      return
    }

    try {
      stopSSE(this.eventSource)
    } catch (e: any) {}
    this.eventSource = null

    this.setIsThinking(false)
    this._collapsed = false

    this.handleQueryResponse(response)

    if (
      this.queryState.i < 10 &&
      this.autoMode &&
      ['assumptions', 'plan', 'code'].includes(this.queryState.state)
    )
      this.ask()
  }

  // public async revise(queryState: QueryState, handleResponse: (queryState: QueryState)=>void) {
  //   let response = null

  //   this.setIsThinking(true)
  //   try {
  //     response = await post2api("/0.2/query", {query_state: queryState})
  //   } catch (e: any) {
  //     error("There was error processing the revision")
  //     this.setIsThinking(false)
  //     return
  //   }
  //   this.setIsThinking(false)
  //   if (response.status=='ok')
  //     handleResponse(response.data)
  // }

  handleQueryResponse(response: any) {
    if (response.status == 'ok') {
      console.log('Query completed', response.data)
      for (var k of Object.keys(response.data)) {
        this.queryState[k as keyof Object] = response.data[k as keyof Object]
      }
      this.queryState = response.data

      this.chatHistory = []
      this.cancelRequest = null
      this.traceId = response.trace
    } else if (response.status == 'error') {
      this.cancelRequest = null
      console.log('AppStore.tsx line 246 response.status == error', response)
      this.queryState.state = 'error:' + this.queryState.state
      console.log(this.queryState)
      console.log('response.data.error, response.message', response.data.error, response.message)
      console.log(this.queryState.error)
      this.queryState.error = response.data.error || response.message || 'Unknown remote error'
      console.log(this.queryState.error)
    }
  }
  public updateKey: number = 0
  public changeUpdateKey() {
    this.updateKey += 1
  }

  public async editVisualization(revision: string) {
    const result = await post2api('/v1.0/edit-visualization', {
      cake_id: this.cakeId,
      session_id: this.sessionId,
      query_id: this.queryState.qid,
      chat_history: [],
      q: this.queryState.question,
      revision: revision,
    })
    if (this.answer) this.answer.chart_html = result.data
  }

  public cancelRequestAction() {
    if (!this.cancelRequest) return
    stopSSE(this.eventSource as EventSource)

    this.cancelRequest.abort()
    this.cancelRequest = null
    this.eventSource = null
    this._collapsed = false
    this.setSampleQuestionVisible(true)
    this.prepareForQuestion(false)
  }

  protected handleSSE(event: MessageEvent) {
    const thought: string = JSON.parse(event['data'])['text']
    this.thoughtsText += thought
    // this.thoughtsText = this.thoughtsText.replace(/`(\S*)`/g, "<code>$1</code>")
    // this.thoughtsText = this.thoughtsText.replace("<br /><br /><br />", "<br /><br />")
    // this.thoughtsText = this.thoughtsText.replace("<br /><code><br />", "<code><br />")
    // this.thoughtsText = this.thoughtsText.replace(/\*\*([A-Za-z0-9_]*?)\*\*/g, "<b>$1</b>")
    // this.thoughtsText = this.thoughtsText.replace(/\*\*([A-Za-z0-9_]*?)\*\*/g, "<code>$1</code>")
    if (thought.length > 10) {
      console.log(thought)
      if (thought.includes('::')) {
        const thought_split: string[] = thought.split('::')
        if (thought_split[0] == 'rephrase') this.queryState.question = thought_split[1]
        else if (thought_split[0] == 'sources')
          this.queryState.sources = JSON.parse(thought_split[1])
        else if (thought_split[0] == 'functions')
          this.queryState.functions = JSON.parse(thought_split[1])
        else if (thought_split[0] == 'code') this.queryState.code = JSON.parse(thought_split[1])
        this.changeUpdateKey()
      }
    }
  }

  public resetQuestion() {
    this.prepareForQuestion(false)
  }

  public prepareForQuestion(isThinking: boolean) {
    // this.queryState = this.input
    this.thoughtsText = ''
    this._thoughtSource = null
    this.setAnswer(null)
    this.error = ''
    this.isThinking = isThinking
    this.isNegativeFeedbackLeft = false
    this.isPositiveFeedbackLeft = false
  }

  // public toggleThoughtsVisible() {
  //   this.isThoughtsVisible = !this.isThoughtsVisible
  // }

  public async flagAnswer(
    queryId: string,
    value: number,
    feedback?: string,
    isDashboard: boolean = false,
  ) {
    let response = null
    try {
      const sessionId = isDashboard ? 'dashboard' : this.sessionId || ''
      const question = isDashboard ? '' : this.queryState.question
      const chatHistory = isDashboard ? [] : this.chatHistory
      response = await flagAnswer(
        this.cakeId,
        queryId,
        sessionId,
        value,
        feedback,
        question,
        chatHistory,
      )
      if (response.status !== 'ok') {
        return
      }

      this.handleSuccess('Your feedback has been recorded. Thank you!')
    } catch (e) {
      this.handleError(e)
      return
    }
  }

  public setIsNegativeFeedbackLeft(value: boolean) {
    this.isNegativeFeedbackLeft = value
  }
  public setIsPositiveFeedbackLeft(value: boolean) {
    this.isPositiveFeedbackLeft = value
  }

  // ### Dashboard State ##################################################################################
  public isDashboardLoading = false
  public setIsDashboardLoading(value: boolean) {
    this.isDashboardLoading = value
  }

  public dashboard: QueryState[] = []
  public setDashboard(value: QueryState[]) {
    this.dashboard = value
  }
  public touchDashboard() {
    this.dashboard = [...this.dashboard]
  }

  public dashboardItems: IDashboardItem[] = []
  public setDashboardItems(value: IDashboardItem[]) {
    this.dashboardItems = value
  }
  public async saveDashboardOrder() {
    const dashboardOrder = this.dashboard.map((item) => item.qid)
    await post2api('/0.2/dashboard-order', { cake_id: this.cakeId, order: dashboardOrder })
  }

  public tempDashboardItems: IDashboardItem[] = []
  public addToTempDashboardItems(value: IDashboardItem) {
    this.tempDashboardItems.push(value)
  }

  public answerIsInDashboard(qState: QueryState) {
    return this.dashboard
      .map((qs: QueryState) => {
        return qs.qid
      })
      .includes(qState.qid)
  }

  public async saveToDashboard(cakeId: string, queryId: string) {
    try {
      const response = await post2api('/0.2/save-to-dashboard', {
        cake_id: cakeId,
        query_id: queryId,
      })
      if (response.status == 'ok') {
        if (!this.answerIsInDashboard(this.queryState)) {
          this.dashboard.push(this.queryState)
        }
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log('Save to dashboard failed', error)
      return false
    }
  }

  public async runAllDashboardItems(cakeId: string, element: HTMLElement) {
    let awaitingNum = this.dashboardItems.length
    this.dashboardItems.map(async (d: IDashboardItem) => {
      console.log('rerunning item...')
      const r = await postToRunDashboardItem(cakeId, d.query_id)
      console.log(r)
      console.log('done', element)
      awaitingNum -= 1
      if (awaitingNum <= 0) await this.loadDashboard(cakeId)
    })
  }

  public async runDashboardItem(cakeId: string, queryId: string, element: HTMLElement) {
    element.innerText = 'Running...'
    const onclick_func = element.onclick
    element.onclick = null
    const result = await postToRunDashboardItem(cakeId, queryId)
    if (result.status == 'ok') {
      element.innerText = 'Done'
      setTimeout(() => {
        element.innerText = 'Re-run analysis'
      }, 3000)
    } else {
      element.onclick = onclick_func
      element.innerText = 'Run failed... Retry?'
    }
  }

  public async deleteDashboardItem(cakeId: string, queryId: string) {
    this.setIsDashboardLoading(true)
    const result = await post2api('/0.2/delete-dashboard-item', {
      cake_id: cakeId,
      query_id: queryId,
    })
    const index = this.dashboardItems
      .map((x) => {
        return x.query_id
      })
      .indexOf(queryId)
    this.setDashboardItems(
      this.dashboardItems.slice(0, index).concat(this.dashboardItems.slice(index + 1)),
    )

    if ((result.status = 'ok')) return true
  }

  public async loadDashboard(cakeId: string) {
    let response = null
    try {
      console.log('Loading dashboard items')
      this.setIsDashboardLoading(true)
      response = await post2api('/0.2/dashboard', { cake_id: cakeId })
      this.setIsDashboardLoading(false)
    } catch (e: any) {
      console.log(e)
      this.handleError(e)
      this.setIsDashboardLoading(false)
      return
    }
    console.log('Done loading dashboard items', response)
    if (response.status == 'ok') {
      this.setDashboard(response.data)
    } else {
      console.log('Error loading dashboard items', response.message)
    }
  }

  public async loadDashboardItems(cakeId: string) {
    let response = null
    try {
      console.log('Loading dashboard items')
      this.setIsDashboardLoading(true)
      response = await post2api('/dashboard-items', { cake_id: cakeId })
      this.setIsDashboardLoading(false)
    } catch (e: any) {
      console.log(e)
      this.handleError(e)
      this.setIsDashboardLoading(false)
      return
    }
    console.log('Done loading dashboard items', response)
    if (response.status == 'ok') {
      this.setDashboardItems(response.data)
    } else {
      console.log('Error loading dashboard items', response.message)
    }
  }

  // ### Constructor ##################################################################################

  constructor() {
    makeAutoObservable(this)
    this.setVersion(getVersionFromUrl())
    this.cakeId = getCakeIdFromUrl()
  }

  // ### Other ##################################################################################

  public error: string = ''
  public showLoginModal = false
  // public isWelcomeModalOpen = true

  // @todo: Rework it: move the modal state to AppStore instead.
  // public modalCloseHandler: Function | null = null

  // // isWelcomeModal initiated for CreateDataCake event
  // public isWelcomeTriggedForCreateDataCake: boolean = false;

  public removeOpenModalParam() {
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  public setShowLoginModal(show: boolean) {
    this.showLoginModal = show
  }

  // // @todo: Rework it: move the modal state to AppStore instead.
  // public setModalCloseHandler(modalCloseHandler: Function) {
  //   this.modalCloseHandler = modalCloseHandler
  // }
  // public callModalCloseHandler() {
  //   if (this.modalCloseHandler == null) {
  //     return
  //   }
  //   this.modalCloseHandler()
  // }

  public async uploadModel(data: FormData) {
    try {
      await postModel(data)
      return true
    } catch (e: any) {
      this.handleError(e)
      return false
    }
  }

  protected handleError(e: any | null) {
    if (e !== null) {
      if (e.name === 'CanceledError') {
        error('Request cancelled by user')

        return
      }

      if (e.response?.data?.instruction == 'login') window.open(LOGIN_URL, '_blank')
      else {
        if (e.response?.data?.display) {
          error(e.response?.data?.display)
        } else if (e.response?.data?.message) {
          error(e.response?.data?.message)
        } else {
          error(e.message)
        }
      }
      return
    }
    error('An error has occurred. Please let the app developers know.')
  }

  protected handleSuccess(message: string) {
    success(message)
  }
}
