import { Box, Button, Typography } from '@mui/material'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import useSettings from '../../hooks/useSettings'
import useUser from '../../hooks/useUser'
import QuestionField from './QuestionField'
import { isEndUserView } from '../../core/utils/main'
import { SampleQuestions } from './SampleQuestions2'
import { Thoughts } from './Thoughts'
import FeedbackControl from './FeedbackControl'
import RevisionModal from '../Modals/RevisionModal'
import { QueryState } from '../../stores/Query'
import BackspaceIcon from '@mui/icons-material/Backspace'
import ShareIcon from '@mui/icons-material/Share'
import CopyToClipboard from 'react-copy-to-clipboard'
import { error, success } from '../../core/services/alerts'
import Answer from './Answer'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    height: '100%',
    maxHeight: '100%',
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',
    justifyContent: 'flex-start',
    flex: '1',
    position: 'relative',
    overflowX: 'auto',
  },
  toggleThoughtsLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontStyle: 'italic',
    cursor: 'pointer',
    color: 'blue',
    textTransform: 'none',
    fontSize: 'inherit',
  },
  saved: {
    // border:"1px solid black",
    padding: '1px 2px',
    margin: '0 6px 0',
  },
  notSaved: {
    // border:"1px solid black",
    padding: '1px 2px',
    margin: '0 6px 0',
    cursor: 'pointer',
  },
  answerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0',
    overflow: 'hidden',
  },
  answerWrapper: {
    width: '100%',
    height: '100%',
    flex: '1 0',
    border: '1px solid black',
    borderBottom: 'none',
  },
  thoughtWrapper: {
    width: '100%',

    flex: '1 1',
    border: '1px solid grey',
    borderBottom: 'none',
  },
  shareButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  feedbackControl: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1.5rem',
    marginTop: '10px',
    marginBottom: '10px',
  },
}))

const SaveToDashboard: FC<{ show: boolean; isSaved: boolean }> = ({ show, isSaved }) => {
  const app = useApp()
  const { classes } = useStyles()

  const handleSaveToDashboard = async (e: MouseEvent) => {
    if (isSaved) return
    e.preventDefault()
    e.stopPropagation()

    const elt = e.target as HTMLElement
    elt.innerText = 'Saving...'
    const result = await app.saveToDashboard(app.queryState.cid, app.queryState.qid)
    if (result) {
      elt.innerText = 'Saved'
    } else {
      error('Save to dashboard failed')
      console.log('Save to Dashboard failed', result)
    }
  }

  if (show)
    return app.answerIsInDashboard(app.queryState) ? (
      <Box className={classes.toggleThoughtsLink}>Saved</Box>
    ) : (
      <Box className={classes.toggleThoughtsLink} onClick={(e) => handleSaveToDashboard(e)}>
        Save to Dashboard
      </Box>
    )
  else return <Box></Box>
}

const Cubie: FC = observer(() => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()

  const [showSaveToDashboard, setShowSaveToDashboard] = useState<boolean>(false)
  const isSaved =
    app.dashboardItems.map((x) => x.query_id).includes(app.answer?.queryId as string) ||
    app.tempDashboardItems.map((x) => x.query_id).includes(app.answer?.queryId as string)
  const [thoughtsVisible, setThoughtsVisible] = useState<boolean>(false)
  const [isReviseOpen, setIsReviseOpen] = useState<boolean>(false)
  const [isShareTooltipOpen, setIsShareTooltipOpen] = useState<boolean>(false)

  useEffect(() => {
    console.log('cake ID', app.cakeId)
    const showS2DB = Boolean(app.queryState.html && (!isEndUserView() || user.isInsider()))
    setShowSaveToDashboard(showS2DB)
  }, [app.queryState])

  const handleBake = () => {
    settings.hasDataSources
      ? settings.setIsCreateDatacakeModalOpen(true)
      : settings.setIsDataSourcesModalOpen(true)
  }

  const toggleThoughts = (e: MouseEvent) => {
    e.preventDefault()
    setThoughtsVisible(!thoughtsVisible)
  }

  const submit = (value: string | null = null) => {
    if (value) app.setQueryStateAttributes({ question: value })
    // app.queryState.question = value

    if (app.queryState.state.slice(0, 6) == 'error:') {
      app.setQueryStateAttributes({ state: app.queryState.state.slice(6, -1) })
    } else {
      app.setQueryStateAttributes({ state: '' })
    }
    app.setQueryStateAttributes({ error: '', html: '' })
    setThoughtsVisible(false)
    app.ask()
  }

  if (!settings.isLoading && !isEndUserView() && !app.cakeId)
    return (
      <Box className="flex min-h-40 items-center justify-center">
        <Button
          className="w-[300px] bg-black px-4 py-1.5 text-white hover:bg-black"
          onClick={handleBake}
        >
          + Bake your first Datacake
        </Button>
      </Box>
    )

  const submitRevision = (s: string) => {
    setIsReviseOpen(false)
    app.setQueryStateAttributes({ state: 'revision' })
    // app.queryState.state = 'revision'

    app.setQueryStateAttributes({ revision: s })
    // app.queryState.revision = s
    // app.queryState.old_html = app.queryState.html

    app.ask()
    app.setQueryStateAttributes({ html: '' })
    // app.queryState.html = ''
  }

  const closeRevision = () => {
    setIsReviseOpen(false)
  }

  return (
    <>
      <RevisionModal isOpen={isReviseOpen} close={closeRevision} onSubmit={submitRevision} />
      <Box className={classes.root}>
        {
          // Title Bar
          app.cakeId && (
            <Box className="align-center relative mt-4 flex w-full justify-center text-center">
              <span className="mt-[5px] inline h-[100%]">
                <Typography className="inline-block" variant="h6">
                  <span
                    className={user.isAuthenticated() && !isEndUserView() ? 'cursor-pointer' : ''}
                    onClick={() => {
                      if (user.isAuthenticated() && !isEndUserView())
                        settings.setIsDatacakesModalOpen(true)
                    }}
                  >
                    {app.cakeName ? app.cakeName : app.cakeId}
                  </span>
                  {/* {app.isDashboardLoading && 
                  <img src={loadinggif} style={{display:'inline', height:'20px', marginLeft:'10px'}}/>
                } */}
                </Typography>
              </span>
              <Box className="absolute right-6 top-2 h-6 w-6">
                <CopyToClipboard
                  text={`${window.location.origin}/${window.location.href.includes('ask') ? 'ask' : 'dashboard'}?cakeId=${app.cakeId}`}
                  onCopy={() => success('URL Copied!')}
                >
                  <ShareIcon
                    data-tooltip-id="share-tooltip"
                    className="cursor-pointer opacity-40 hover:opacity-100"
                  />
                </CopyToClipboard>
                <Tooltip
                  id="share-tooltip"
                  clickable={true}
                  opacity={1}
                  isOpen={isShareTooltipOpen}
                  setIsOpen={setIsShareTooltipOpen}
                >
                  Click to copy the URL to share this Datacake with others.
                </Tooltip>
              </Box>
            </Box>
          )
        }
        <Box className="QUESTION flex-0 mt-4 w-full px-4">
          {app.queryState.question ? (
            <Box>
              <span className="font-bold italic">Q. {app.queryState.question}</span>
              <span
                className="ml-2 opacity-40 hover:opacity-100"
                onClick={() => {
                  app.queryState = new QueryState()
                }}
              >
                <BackspaceIcon fontSize="small" />
              </span>
            </Box>
          ) : (
            <QuestionField
              autoFocus
              disabled={app.isThinking}
              onFinish={submit}
              placeholder="Ask a question about your data..."
            />
          )}
          {!app.isThinking &&
            !app.queryState.html &&
            !app.queryState.answer &&
            settings.sampleQuestions?.length > 0 && (
              <Box className="flex w-full flex-col">
                <SampleQuestions />
              </Box>
            )}
        </Box>
        <Box className="ANSWER_CONTAINER flex w-full flex-1 flex-col overflow-auto px-10">
          {app.queryState.html && (
            <Box className="REVIEW w-full shrink-0 grow-0 px-1 pt-1">
              <Box className="align-center flex h-[20px] w-full justify-between text-xs text-black">
                <Box className={classes.toggleThoughtsLink} onClick={toggleThoughts}>
                  {thoughtsVisible ? "Hide Cubie's work..." : "Review Cubie's work"}
                </Box>
                <SaveToDashboard show={showSaveToDashboard} isSaved={isSaved} />
              </Box>
            </Box>
          )}
          {app.queryState.question &&
            (thoughtsVisible ||
              app.isThinking ||
              (!app.isThinking && !app.queryState.answer && !app.queryState.html) ||
              ['error', 'clarification', 'plan', 'assumptions'].includes(app.queryState.state) ||
              (thoughtsVisible && app.queryState.state.includes('error'))) && (
              <Box className="THOUGHTS w-full shrink-0 grow-0 overflow-auto pr-4">
                <Thoughts submit={submit} />
              </Box>
            )}
          {app.queryState.answer && (
            <Box className="ANSWER relative flex w-full flex-1 shrink-0 flex-col">
              <Box className="p-4">{app.queryState.answer}</Box>
            </Box>
          )}
          {app.queryState.html && !thoughtsVisible && (
            <>
              <Box className="ANSWER relative flex w-full flex-1 shrink-0 flex-col overflow-y-auto bg-white ">
                {app.queryState.html && <Answer answer={app.queryState} editable={true} />}
              </Box>
              <Box className="flex-0 shrink-1 flex w-full flex-row items-center justify-between px-4">
                {/*
              <Box className="flex-0 cursor-pointer border border-black px-2" onClick={()=>console.log("HERHERHERHERH")}>Follow-up Question</Box>
              */}
                <Box
                  className="flex-0 cursor-pointer border border-black px-2 text-sm"
                  onClick={() => setIsReviseOpen(true)}
                >
                  Edit Visualizations
                </Box>
                <Box className="flex-0 justify-flex-start mb-[10px] mt-[10px] flex items-center gap-1.5">
                  <span className="text-xs">How did I do?</span>
                  <FeedbackControl queryId={app.queryState.qid} isDashboard={false} />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
})
export default Cubie
