import { useEffect, useState, FC } from 'react'
import { makeStyles } from '../../core/utils/theme'
import { Box, Button, Tooltip } from '@mui/material'
import useSettings from '../../hooks/useSettings'
import useUser from '../../hooks/useUser'
import useApp from '../../hooks/useApp'
import { observer } from 'mobx-react'
import { IDatacake } from '../../core/types/source_service/IDatacake'
import NeedMoreDataCakesModal from '../Modals/NeedMoreDataCakesModal'
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ContentModal from '../Modals/ContentModal'
import { LoadingSpinner } from '../Misc/Loader'

const useStyles = makeStyles()(() => ({
  table: {
    // width:'100%',
    minWidth: '100%',
    maxWidth: '100%',
    '& td': {
      border: '0',
    },
  },
  tdactive: {
    color: 'darkblue',
    textAlign: 'center',
  },
  tddelete: {
    width: '60px',
  },
  tdname: {
    minWidth: '40%',
  },
  tdright: {},
  switch: {
    width: '100%',
    height: '100%',
    visibility: 'hidden',
  },
  button: {
    color: 'grey',
    borderColor: 'grey',
    '&:hover': {
      color: 'darkblue',
      borderColor: 'darkblue',
    },
    margin: '0 4px 0 4px',
  },
  icon: {
    color: 'grey',
    '&:hover': {
      color: 'darkblue',
    },
    cursor: 'pointer',
    marginLeft: '4px',
    marginRight: '4px',
  },
  hidden: {
    visibility: 'hidden',
  },
  bakeButton: {
    width: '100%',
    color: 'black',
    border: '1px solid black',
    padding: '10px 14px',
    margin: '24px 0 0 0',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      background: '#eee',
    },
  },
}))

const Datacakes: FC = observer(() => {
  const { classes, cx } = useStyles()
  const settings = useSettings()
  const user = useUser()
  const app = useApp()
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [cakeIdToBeDeleted, setCakeIdToBeDeleted] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    settings
      .loadDatacakes()
      .then(() => {
        setIsLoading(false)
        console.log('Data receipes loaded')
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('Unable to load recipes', err)
      })
  }, [])

  const handleDeleteModalClose = () => {
    setCakeIdToBeDeleted('')
    settings.setIsDatacakesModalOpen(true)
    setIsDeleteModalOpen(false)
  }

  const handleEdit = () => {
    settings.setIsCreateDatacakeModalOpen(true, true)
  }
  const handleDuplicate = () => {
    console.log('duplicating')
  }

  const handleMouseEnter = (i: number) => {
    console.log(i)
    const box = document.getElementById(`box_${i}`)
    if (box) box.style['visibility'] = 'visible'
    const del = document.getElementById(`del_${i}`)
    if (del) del.style['visibility'] = 'visible'
  }

  const handleMouseLeave = (i: number) => {
    const box = document.getElementById(`box_${i}`)
    if (box) box.style['visibility'] = 'hidden'
    const del = document.getElementById(`del_${i}`)
    if (del) del.style['visibility'] = 'hidden'
  }

  const handleSwitch = (item: IDatacake) => {
    app.resetCubie
    settings.setIsDatacakesModalOpen(false)
    if (item && item.cake_id) {
      app.setCakeId(item.cake_id)
      app.setCakeName(item.name || '')
      settings.reportState(item.cake_id)
      app.setDashboardItems([])
      app.loadDashboard(item.cake_id)
      app.updateSources()
    }
  }

  const handleTrashClick = (i: number) => {
    console.log('DELETE', i)
    setCakeIdToBeDeleted(settings.datacakes[i].cake_id || '')
    console.log('CakeIdToBeDeleted', cakeIdToBeDeleted)
    setIsDeleteModalOpen(true)
  }

  const handleDelete = async () => {
    if (cakeIdToBeDeleted) {
      setIsLoading(true)
      setIsDeleteModalOpen(false)
      await settings.deleteSelectedDatacake(cakeIdToBeDeleted)
      // Refetch the recipes
      await settings.loadDatacakes()
      setIsLoading(false)
    } else {
      Error('There was a problem deleting the datacake.')
      setIsDeleteModalOpen(false)
    }
  }

  const handleNewDatacake = () => {
    settings.setIsDatacakesModalOpen(false)
    if (!settings.hasDataSources) {
      settings.setIsDataSourcesModalOpen(true)
      // settings.setIsCreateDatacakeModalOpen(true, false)
      return
    }
    if (!user.isInsider() && settings.datacakes && settings.datacakes.length >= 1) {
      setIsContactUsModalOpen(true)
      return
    }

    settings.setIsCreateDatacakeModalOpen(true, false)
  }

  return (
    <>
      <NeedMoreDataCakesModal
        open={isContactUsModalOpen}
        onClose={() => setIsContactUsModalOpen(false)}
      />
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        title="Delete"
        type="recipe"
        description="You are about to delete a Datacake Recipe!"
        onDelete={handleDelete}
      />
      <Box className="flex h-full w-full flex-col overflow-hidden">
        <Box className="mx-8 flex max-h-[70vh] min-h-[500px] flex-1 overflow-hidden border border-black">
          <Box className="h-full w-full overflow-auto">
            {!settings.datacakes ? (
              <Box className="min-h-[400px] flex-1 p-12">No Datacakes</Box>
            ) : isLoading ? (
              <Box className="flex h-full w-full items-center justify-center">
                <LoadingSpinner className="h-8 w-8" />
              </Box>
            ) : (
              <Table className={classes.table}>
                <TableBody className="overflow-auto">
                  {settings.datacakes?.map(
                    (item: IDatacake, i: number) =>
                      app.cakeId === item.cake_id && (
                        <TableRow key={i} sx={{ backgroundColor: '#eef' }}>
                          <TableCell className={classes.tddelete}>
                            <DeleteForeverIcon
                              id={`del_${i}`}
                              className={classes.icon}
                              onClick={() => handleTrashClick(i)}
                            />
                          </TableCell>
                          <TableCell className={classes.tdname}>
                            <b>{item.name}</b>
                          </TableCell>
                          <TableCell className={classes.tdactive}>
                            <b>Active</b>
                          </TableCell>
                          <TableCell className="flex justify-center">
                            <Tooltip title="Edit Datacake">
                              <EditIcon className={classes.icon} onClick={handleEdit} />
                            </Tooltip>
                            <Tooltip title="Duplicate datacake (coming soon)">
                              <ContentCopyIcon className={classes.icon} onClick={handleDuplicate} />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ),
                  )}
                  {settings.datacakes?.map(
                    (item: IDatacake, i: number) =>
                      app.cakeId !== item.cake_id && (
                        <TableRow
                          key={i}
                          hover={true}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={() => handleMouseLeave(i)}
                        >
                          <TableCell className={classes.tddelete}>
                            <DeleteForeverIcon
                              id={`del_${i}`}
                              className={cx(classes.icon, classes.hidden)}
                              onClick={() => handleTrashClick(i)}
                            />
                          </TableCell>
                          <TableCell className={classes.tdname}>{item.name}</TableCell>
                          <TableCell className={classes.tdactive}>
                            <Box className={classes.switch} id={`box_${i}`}>
                              <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={() => handleSwitch(item as IDatacake)}
                              >
                                Activate
                              </Button>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </Box>
        <Box className="flex-0 flex w-full justify-end px-8 py-4">
          <Box className="border border-black px-4" onClick={handleNewDatacake}>
            Bake a New Datacake
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
    </>
  )
})

const modalStyle: ReactModal.Styles = {   content: {     maxWidth:"600px"   } }

const DatacakesModal: FC = observer(() => {
  const settings = useSettings()

  const handleClose = () => {
    settings.setIsDatacakesModalOpen(false)
  }

  return (
    <ContentModal
      style={modalStyle} 
      open={settings.isDatacakesModalOpen}
      title={'Your Datacakes'}
      showCloseButton={true}
      onClose={handleClose}
    >
      <Datacakes />
    </ContentModal>
  )
})

export default DatacakesModal
