import PageLayout from '../Layouts/PageLayout'
import { observer } from 'mobx-react'
import LeftSidebar from '../LeftSidebar/LeftSidebar'
import { Box, ClickAwayListener, IconButton, useMediaQuery } from '@mui/material'
import theme, { makeStyles } from '../../core/utils/theme'
import Cubie from './Cubie'
import { FC, MouseEvent, useEffect } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import useApp from '../../hooks/useApp'
import CubieSettings from '../Misc/CubieSettings'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    margin: 'auto',
  },
  sidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '325px',
    background: theme.palette.background.paper,
    boxShadow: '2px 0 5px rgba(0,0,0,0.2)',
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateX(-100%)',
    zIndex: 10,
  },
  sidebarOpen: {
    transform: 'translateX(0)',
  },
  fixedSidebar: {
    height: '100vh',
    width: '325px',
    background: theme.palette.background.paper,
    zIndex: 10,
  },
  menuButton: {
    position: 'fixed',
    top: 10,
    left: 10,
    zIndex: 5,
  },
}))

const AskCubie: FC = observer(() => {
  const { classes, cx } = useStyles()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const app = useApp()

  const openSidebar = (e: MouseEvent) => {
    e.stopPropagation()
    app.setIsSidebarOpen(true)
  }

  const closeSidebar = () => {
    app.setIsSidebarOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      app.setIsSidebarOpen(false)
    }, 1000)
  }, [])

  return (
    <PageLayout>
      <Box className={classes.root}>
        {/* Sidebar Toggle Button */}
        {isSmallScreen && !app.isSidebarOpen && (
          <IconButton
            component="div"
            className={classes.menuButton}
            onClick={(e: MouseEvent) => {
              openSidebar(e)
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* Sidebar */}
        {isSmallScreen ? (
          <>
            <ClickAwayListener onClickAway={closeSidebar}>
              <Box
                component="div"
                className={cx(classes.sidebar, { [classes.sidebarOpen]: app.isSidebarOpen })}
              >
                <LeftSidebar />
              </Box>
            </ClickAwayListener>
          </>
        ) : (
          <>
            <Box component="div" className={classes.fixedSidebar}>
              <LeftSidebar />
            </Box>
            <Box className="relative flex w-full justify-center">
              <Cubie />
              <Box className="z-5 absolute bottom-2 left-2 text-xs">
                <CubieSettings />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </PageLayout>
  )
})

export default AskCubie
