import { FC, SetStateAction, KeyboardEvent, useState } from 'react'
import { IColumn, IDataObject } from '../../core/types/code_service/IDataObject'
import DataTable from '../Misc/DataTable'
import ContentModal from './ContentModal'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'

export const ColumnDescriptions: FC<{ sources: IDataObject[] }> = ({ sources }) => {
  const [filter, setFilter] = useState('')

  const handleFilterChange = (event: { target: { value: SetStateAction<string> } }) => {
    setFilter(event.target.value)
  }

  const handleEsc = (event: KeyboardEvent) => {
    event.stopPropagation()
    event.preventDefault()
    if (event.key == 'Escape') {
      setFilter('')
    }
  }

  const filteredColumns = (columns: IColumn[]) => {
    const filtered = columns?.filter(
      (col: IColumn) =>
        col.name.toLowerCase().includes(filter.toLowerCase()) ||
        col.description?.toLowerCase().includes(filter.toLowerCase()),
    )
    if (filtered.length == 0) {
      return (
        <TableRow>
          <TableCell colSpan={2} className="">
            <Box className="w-full text-center">&mdash;</Box>
          </TableCell>
        </TableRow>
      )
    } else {
      return filtered.map((col: IColumn) => (
        <TableRow key={col.name}>
          <TableCell sx={{ padding: '3px 6px !important', lineHeight: '1.3', fontWeight: 'bold' }}>
            {col.name}
          </TableCell>
          <TableCell sx={{ padding: '3px 8px!important', lineHeight: '1.3' }}>
            {col.description}
          </TableCell>
        </TableRow>
      ))
    }
  }

  return (
    <Box className="relative flex w-full flex-col">
      {/* {isSearching ? */}
      <Box className="flex justify-center">
        <TextField
          autoFocus
          value={filter}
          onChange={handleFilterChange}
          onKeyUp={handleEsc}
          // onBlur={() => setIsSearching(false)}
          variant="standard"
          placeholder="Search Columns"
          className="w-[50%]"
        />
      </Box>
      {/* //   : 
        //   <Box className="absolute right-0 top-1" onClick={() => setIsSearching(true)}>
        //     <SearchIcon />
        //   </Box>
        // } */}
      {sources.map((source: IDataObject, i: number) => (
        <Box key={i}>
          <Box className="my-2 flex w-full items-center justify-center text-sm">
            <Box className="w-full px-8">
              <span className="font-bold">{source.name}:</span> {source.description}
            </Box>
          </Box>
          <Box className="overflowX-auto mb-2 flex w-full justify-center">
            <Table className="w-full" sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ padding: '3px 6px !important', lineHeight: '1.3', fontWeight: 'bold' }}
                  >
                    Column
                  </TableCell>
                  <TableCell
                    sx={{ padding: '3px 6px !important', lineHeight: '1.3', fontWeight: 'bold' }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{filteredColumns(source.columns || [])}</TableBody>
            </Table>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

interface IProps {
  source: IDataObject
  open: boolean
  onClose: () => void
  limit: number
}
const DataObjectHeadModal: FC<IProps> = ({ source, open = false, onClose, limit }) => {
  const [mode, setMode] = useState<string>('columns')

  return (
    <ContentModal open={open} onClose={onClose}>
      <Box className="flex h-full w-full flex-col">
        <Box className="flex-0 align-center flex h-8 justify-center gap-8 border border-y-black ">
          <Box
            className={`flex h-full w-[50%] flex-col justify-center text-center ${mode == 'data' ? 'bg-gray-300 font-bold' : 'cursor-pointer'}`}
            onClick={() => {
              mode == 'columns' && setMode('data')
            }}
          >
            Data
          </Box>
          <Box
            className={`flex h-full w-[50%] flex-col justify-center text-center ${mode == 'columns' ? 'bg-gray-300 font-bold' : 'cursor-pointer'}`}
            onClick={() => {
              mode == 'data' && setMode('columns')
            }}
          >
            Columns
          </Box>
        </Box>
        <Box className="ABCDE h-full w-full flex-1 overflow-auto px-2">
          {mode == 'data' ? (
            <Box>
              <Box className="w-full py-1 text-center text-sm font-bold">{source.name}</Box>
              <DataTable
                columns={source.sample.columns}
                data={source.sample.data}
                rowLimit={limit}
              />
            </Box>
          ) : (
            <ColumnDescriptions sources={[source]} />
          )}
        </Box>
      </Box>
    </ContentModal>
  )
}

export default DataObjectHeadModal
