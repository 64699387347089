import { IAnswerResponse } from '../core/types/code_service/IAnswerResponse'

export class QueryState {
  version: string = '0.2'
  cid: string = ''
  sid: string = ''
  qid: string = ''
  state: string = ''
  question: string = ''
  assumptions: string[] = []
  clarification: string = ''
  clarifications: string[][] = []
  plan: string[] = []
  instructions: Object = {}
  sources: { [key: string]: Array<string> } | null = null
  libraries: string[] = []
  functions: string[] = []
  code: string[] = []
  answer: string = ''
  v1answer: IAnswerResponse = { answer: '', data: [] }
  html: string = ''
  url: string = ''
  error: string = ''
  revision: string = ''
  revisions: string[][] = []
  visualizations: { [name: string]: string } = {}
  data: { [name: string]: string } = {}
  i: number = 0

  constructor(q: string = '') {
    this.state = 'new'
    this.question = q
    this.i = 0
  }
}
