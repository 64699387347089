import { Box, TextField, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { makeStyles } from '../../core/utils/theme'
import useSettings from '../../hooks/useSettings'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import { srcSvcGet, srcSvcPost } from '../../core/services/source_service'
import ContentModal from './ContentModal'
import loadinggif from '../../assets/loading.gif'
import { success } from '../../core/services/alerts'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

const Instructions: FC = observer(() => {
  const { classes } = useStyles()
  const app = useApp()
  const settings = useSettings()
  const [loadStatus, setLoadStatus] = useState<string>('unloaded')
  const [instructionsAnalysis, setInstructionsAnalysis] = useState<string>('')
  const [instructionsVisualization, setInstructionsVisualization] = useState<string>('')

  useEffect(() => {
    const fetchInstructions = async () => {
      if (app.cakeId) {
        setLoadStatus('loading')
        const instr = await srcSvcGet('/instructions', { cake_id: app.cakeId })
        setInstructionsAnalysis(instr.analysis)
        setInstructionsVisualization(instr.visualization)
        setLoadStatus('loaded')
      }
    }
    if (settings.isInstructionsModalOpen && loadStatus == 'unloaded') {
      setInstructionsAnalysis('')
      setInstructionsVisualization('')
      fetchInstructions()
    }
  }, [
    settings.isInstructionsModalOpen,
    instructionsAnalysis,
    instructionsVisualization,
    loadStatus,
  ])

  const handleSubmit = async () => {
    setLoadStatus('loading')
    const result = await srcSvcPost('/instructions', {
      cake_id: app.cakeId,
      instructions: { analysis: instructionsAnalysis, visualization: instructionsVisualization },
    })
    setLoadStatus('loaded')
    console.log('post to /instructions result', result)
    if (result) {
      settings.setIsInstructionsModalOpen(false)
      setLoadStatus('unloaded')
      success('Success.')
    }
  }

  return (
    <Box className={`${classes.root} flex flex-col`}>
      <Box className="gap-1  px-4">
        <Typography variant="body1" className="font-300 mb-2 w-full">
          Help Cubie understand your data...
          {loadStatus != 'loaded' && (
            <img
              src={loadinggif}
              style={{ display: 'inline', height: '20px', margin: '0 0 2px 8px' }}
            />
          )}
        </Typography>
        <TextField
          className="w-full"
          multiline
          rows={4}
          value={instructionsAnalysis}
          onChange={(e) => {
            setInstructionsAnalysis(e.target.value)
          }}
        ></TextField>
        <Box className="h-4"></Box>
        <Typography variant="body1" className="font-300 mb-2 w-full">
          Tell Cubie how you like your visualizations...
          {loadStatus != 'loaded' && (
            <img
              src={loadinggif}
              style={{ display: 'inline', height: '20px', margin: '0 0 2px 8px' }}
            />
          )}
        </Typography>
        <TextField
          className="w-full"
          multiline
          rows={4}
          value={instructionsVisualization}
          onChange={(e) => {
            setInstructionsVisualization(e.target.value)
          }}
          onKeyUp={(e) => {
            if (e.key == 'Enter' && (e.ctrlKey || e.metaKey)) handleSubmit()
          }}
        ></TextField>
      </Box>
      <Box className="flex-0 mx-4 my-4 flex justify-end text-right">
        <Box className="cursor-pointer border border-black px-4" onClick={handleSubmit}>
          Submit
        </Box>
      </Box>
    </Box>
  )
})

interface IProps {}
const InstructionsModal: FC<IProps> = observer(() => {
  const settings = useSettings()

  const handleClose = () => {
    settings.setIsInstructionsModalOpen(false)
  }

  return (
    <>
      <ContentModal
        open={settings.isInstructionsModalOpen}
        title="Instructions"
        onClose={handleClose}
      >
        <Instructions />
      </ContentModal>
    </>
  )
})

export default InstructionsModal
