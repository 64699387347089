import { FC, useState } from 'react'
import ContentModal from '../Modals/ContentModal'
import { Box, Grid, Typography } from '@mui/material'
import ButtonLink from '../Misc/ButtonLink'
import Loader from '../Misc/Loader'
import { observer } from 'mobx-react'
import { IMktDatasetInfo } from '../../core/types/source_service/IMktDatasetInfo'
import useSettings from '../../hooks/useSettings'
import { useNavigate } from 'react-router-dom'

interface IProps {
  open: boolean
  cake: IMktDatasetInfo
  onClose: () => void
}

const MktDatasetDetailsModal: FC<IProps> = observer(({ open, cake, onClose }) => {
  const [isWorking, setIsWorking] = useState(false)
  const [addSucceeded, setAddSucceeded] = useState(false)
  const settings = useSettings()
  const navigate = useNavigate()

  const handleAddDataset = (cakeId: string) => {
    setIsWorking(true)
    settings.addMarketDataset(cakeId)
    setIsWorking(false)
    setAddSucceeded(true)
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setIsWorking(false)
      setAddSucceeded(false)
    }, 1000)
  }

  const handleBakeDatacake = () => {
    settings.setIsCreateDatacakeModalOpen(true, false)
    handleClose()
    navigate('/')
  }

  return (
    <ContentModal open={open} onClose={handleClose} title={cake.name}>
      <Box className="flex min-h-full flex-col p-6">
        <Box>
          <Typography variant="h6">
            <b>Datacake Name:</b> {cake.name}
          </Typography>
          <Typography variant="body1">
            <b>Description:</b> {cake.description}
          </Typography>
          <Typography variant="body2">
            <b>Added on</b> {cake.created_on}
          </Typography>
          <Typography variant="body2">
            <b>Added by</b> {cake.chef}
          </Typography>
        </Box>
        <Box className="flex-1-1 items-right m-4" sx={{ textAlign: 'right' }}>
          {!isWorking && !addSucceeded && (
            <>
              <ButtonLink
                className="ml-5 border border-black bg-white text-black"
                onClick={() => {
                  window.open(`/dashboard?cakeID=${cake.cakeId}`)
                }}
              >
                <Typography className="inline">Explore this Datacake</Typography>
              </ButtonLink>
              <ButtonLink
                className="ml-5 border border-black bg-white text-black"
                onClick={() => {
                  handleAddDataset(cake.cakeId)
                }}
              >
                <Typography className="inline">Add to your Data Sources</Typography>
              </ButtonLink>
            </>
          )}
          {isWorking && <Loader className="self-center" />}
          {addSucceeded && !isWorking && (
            <>
              <Typography color="green" fontStyle="italic" fontWeight="bold">
                Successfully Added!
              </Typography>
              {/* <Box className='mt-5'> */}
              <Grid
                container
                columns={2}
                className=""
                spacing={2}
                alignItems={'center'}
                sx={{ marginTop: '5px', display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  item
                  sm={2}
                  md={2}
                  lg={1}
                  sx={{ display: 'flex', flexDirection: 'column', margin: '0' }}
                >
                  <ButtonLink
                    className="bg-white text-black"
                    style={{ flex: '1 1 auto' }}
                    onClick={() => {
                      settings.setIsDataSourcesModalOpen(true)
                      navigate('/ask')
                      handleClose()
                    }}
                  >
                    <Typography>View Your Data Sources</Typography>
                  </ButtonLink>
                  <ButtonLink
                    className="bg-white text-black"
                    style={{ flex: '1 1 auto' }}
                    onClick={handleClose}
                  >
                    <Typography>Keep Browsing Datasets</Typography>
                  </ButtonLink>
                </Grid>
                <Grid item className="" sx={{ flex: '1', display: 'flex', flexDirection: 'row' }}>
                  <ButtonLink
                    className="cursor-pointer border border-black bg-white text-black"
                    style={{ flex: '1' }}
                    onClick={handleBakeDatacake}
                  >
                    <Typography className="inline" style={{ lineHeight: '65px' }}>
                      Bake Your Datacake
                    </Typography>
                  </ButtonLink>
                </Grid>
              </Grid>
              {/* </Box> */}
            </>
          )}
        </Box>
      </Box>
    </ContentModal>
  )
})

export default MktDatasetDetailsModal
