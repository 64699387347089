import { Box, Grid, IconButton, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useEffect, useState, MouseEvent } from 'react'
import { CONNECT_URL } from '../../core/config/main'
import { makeStyles } from '../../core/utils/theme'
import { getDataServiceIcon } from '../../core/utils/main'
import useSettings from '../../hooks/useSettings'
// import { useNavigate } from 'react-router-dom'
import { IDataSource } from '../../core/types/source_service/IDataSource'
import Loader from '../Misc/Loader'
import useUser from '../../hooks/useUser'
import ContentModal from '../Modals/ContentModal'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles()((theme: any) => ({
  root: {
    background: 'white',
    // border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('md')]: {
      padding: '20px 0px',
      // height: '90%',
      overflow: 'auto',
    },
    minHeight: '600px',
  },
  connect_own_src: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > p': {
      color: theme.palette.text.disabled,
    },
  },
}))

const DataServices: FC = () => {
  const { classes } = useStyles()
  // const navigate = useNavigate()
  const settings = useSettings()
  const user = useUser()

  const handleSelectService = (svc: string) => {
    settings.setIsDataSourcesModalOpen(false)
    settings.setIsDataSourceManagerModalOpen(true, svc)
  }

  return (
    <>
      <Box className="mb-2">
        <Typography variant="subtitle1" className="mb-0 font-bold">
          Attach a data source of your own:
        </Typography>
      </Box>
      <Box className={classes.connect_own_src}>
        <Grid container columns={5} spacing={1} className="flex justify-center">
          <Grid item className="mb-2">
            <DataServiceButton
              source="csv"
              label="CSV File"
              onClick={() => handleSelectService('csv')}
            />
          </Grid>
          <Grid item>
            <DataServiceButton
              source="bigquery"
              label="BigQuery"
              onClick={() => {
                window.location.href = CONNECT_URL('bigquery')
              }}
            />
          </Grid>
          <Grid item className="relative">
            <DataServiceButton
              source="snowflake"
              label="Snowflake"
              onClick={() => {
                user.isInsider()
                  ? (alert('Only accessible to @datacakes.ai users'),
                    handleSelectService('snowflake'))
                  : 'disabled'
              }}
            />
            <span className="absolute right-1 top-2 text-xs italic text-red-500">coming soon</span>
          </Grid>
          <Grid item className="relative">
            <DataServiceButton
              source="googleads"
              label="Google Ads"
              onClick={() => {
                user.isInsider()
                  ? (alert('Only accessible to @datacakes.ai users'),
                    (window.location.href = CONNECT_URL('googleads')))
                  : 'disabled'
              }}
            />
            <span className="absolute right-1 top-2 text-xs italic text-red-500">coming soon</span>
          </Grid>
          <Grid item className="relative">
            <DataServiceButton
              source="googleanalytics"
              label="Google Analytics"
              onClick={() => {
                user.isInsider()
                  ? (alert('Only accessible to @datacakes.ai users'),
                    (window.location.href = CONNECT_URL('googleanalytics')))
                  : 'disabled'
              }}
            />
            <span className="absolute right-1 top-2 text-xs italic text-red-500">coming soon</span>
          </Grid>
          <Grid item className="relative">
            <DataServiceButton source="meta" label="Meta Ads" onClick="disabled" />
            <span className="absolute right-1 top-2 text-xs italic text-red-500">coming soon</span>
          </Grid>
          {/* {user.isInsider() && 
          <Grid item className="relative">
            <DataServiceButton source='datacakes' label='Datacakes' onClick={()=>{user.isRob() ? (alert("Rob only"), navigate('/datamarket')) : 'disabled'}} />
            <span className="absolute text-xs text-red-500 italic right-1 top-2">coming soon</span>
          </Grid>
          } */}
        </Grid>
      </Box>
    </>
  )
}

const srcDisplayMap = {
  bigquery: 'BigQuery',
  duckdb: 'CSV',
  csv: 'CSV',
  snowflake: 'Snowflake',
  googleads: 'Google Ads',
  googleanalytics: 'Google Analytics',
  datacakes: 'Datacake',
  datacake: 'Datacake',
}

const DataSources: FC = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  // const user = useUser()
  const deletable = [
    'snowflake',
    'datacakes',
    'datacake',
    'bigquery',
    'googleads',
    'googleanalytics',
  ]
  const manageable = ['googleads', 'googleanalytics', 'csv']
  // const app = useApp()

  const handleDelete = (src_id: string) => {
    settings.deleteDataSource(src_id)
  }
  const handleManage = (src: IDataSource) => {
    settings.dataSourceIdBeingManaged = src.id
    settings.setIsDataSourceManagerModalOpen(true, src.type)
    settings.setIsDataSourcesModalOpen(false)
  }

  return (
    <Box className={classes.connect_own_src}>
      <Box className="w-full">
        <Typography variant="subtitle1" className="mb-3 mt-10 font-bold">
          {settings.dataSources.length > 0 ? 'Your connected data sources:' : ''}
        </Typography>
      </Box>
      <Box className="w-[80%]">
        {settings.dataSources.map((src) => {
          const is_deletable = deletable.includes(src.type)
          const is_manageable = manageable.includes(src.type)
          return (
            <div key={src.id} className="">
              <CloseIcon
                className={is_deletable ? 'visible mr-10 cursor-pointer' : 'invisible mr-10'}
                onClick={() => {
                  if (is_deletable) handleDelete(src.id)
                }}
              />
              <span key={src.id}>
                {srcDisplayMap[src.type as keyof object] || 'Other'}{' '}
                {src.identifier ? `: ${src.identifier}` : ''}
              </span>
              {is_manageable && (
                <span
                  className="blue ml-5 cursor-pointer text-xs"
                  onClick={() => handleManage(src)}
                >
                  manage
                </span>
              )}
            </div>
          )
        })}
      </Box>
    </Box>
  )
})

interface IDataServiceButton {
  source: string
  label: string
  destination?: string
  onClick?: string | ((e?: MouseEvent) => void)
}
const DataServiceButton: FC<IDataServiceButton> = ({ source, label, onClick = 'disabled' }) => {
  const icon = getDataServiceIcon(source)
  const settings = useSettings()

  return (
    <Box className="hover:border-[#00ECFF] ">
      <Box
        className="cursor-pointer border border-slate-700 px-2 py-2 hover:border-black"
        onClick={() => {
          if (onClick == 'disabled') {
            return
          } else {
            settings.setIsDataSourcesModalOpen(false)
            ;(onClick as Function)()
            return
          }
        }}
      >
        <IconButton disableRipple>
          <img src={icon} className="mr-3" style={{ height: '26px' }} />
          <Typography variant="button" color={'black'}>
            {label}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  )
}

const DataSourcesModal: FC = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const user = useUser();

  const handleBakeCake = () => {
    settings.setIsDataSourcesModalOpen(false)
    settings.setIsCreateDatacakeModalOpen(true)
  }

  useEffect(() => {
    if (settings.isDataSourcesModalOpen) {
      setIsLoading(true)
      settings
        .loadDataSources()
        .then(() => {
          setIsLoading(false)
        })
        .catch((e) => {
          console.log('Error in loading data sources', e)
        })
    }
  }, [settings.isDataSourcesModalOpen])

  return (
    <ContentModal
      open={settings.isDataSourcesModalOpen}
      title={'Data Sources'}
      showCloseButton={true}
      onClose={() => settings.setIsDataSourcesModalOpen(false)}
    >
      <Box
        className={`${classes.root} m-0 flex w-full flex-col px-8 py-8 md:m-auto md:max-w-[90%] lg:max-w-full`}
      >
        <Box className="grow">
          <DataServices />
          {isLoading && <Loader className="w-[40px]" />}
          {!isLoading && (
            <Box className="grow">
              <DataSources />
            </Box>
          )}
        </Box>
        <Box className="flex w-full cursor-pointer justify-end">
          {settings.dataSources.length > 0 && (
            <span className="m-2 mr-4 cursor-pointer" onClick={handleBakeCake}>
              Bake a Datacake &gt;
            </span>
          )}
        </Box>
      </Box>
    </ContentModal>
  )
})

export default DataSourcesModal
