import { observer } from 'mobx-react'
import { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import theme, { makeStyles } from '../../core/utils/theme'
import Modal from 'react-modal'
import StepSelectTables from './StepperPanels/StepSelectTables'
import StepDescribeColumns from './StepperPanels/StepDescribeColumns'
import { warning } from '../../core/services/alerts'

import { createLocalTableLink } from '../../core/utils/table_setup'
import { ITableLinkColumnLocal } from '../../core/types/source_service/ITableLinkColumnLocal'
import useSettings from '../../hooks/useSettings'
import { ITable } from '../../core/types/source_service/ITable'
import StepLinks from './StepperPanels/StepLinks'

// Icon
import CLOSE from '../../assets/Svgs/navigation/close_24px.svg'
import SELECTALL from '../../assets/Svgs/action/select_all_24px.svg'
import DESELECTALL from '../../assets/Svgs/action/deselect_all_24px.svg'
import { areArraysEqual } from '../../core/utils/main'
import { IDatacake } from '../../core/types/source_service/IDatacake'
import useApp from '../../hooks/useApp'
import { fetchInstructions } from '../../core/services/source_service'

// const stepDescription = [
//   "",
//   "Select the tables that you want Cubie to access. This version of Cubie is limited to 10 tables. Please contact us if you'd like Cubie to handle more.",
//   "Select the columns that you want Cubie to access. You can edit the table/column descriptions suggested by Cubie if needed to improve performance.",
//   "Tell Cubie how your tables should be connected (joined), if any connections exist. Each connection is between two tables and should specify a column (or 'key') from each table."
// ]

const useStyles = makeStyles()(() => ({
  customSteps: {
    '& .MuiStepLabel-alternativeLabel': {
      color: '#0A191C',
    },
  },
  contentBox: {
    paddingTop: '25px',
    position: 'relative', // This is necessary for z-index to take effect
    zIndex: 1200, // Example z-index value
  },
  descriptionContainer: {
    minWidth: '10%', // Adjust as needed
  },
  loader: {
    margin: '0.5rem auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    minWidth: '10px',
  },
}))

enum STEP {
  Tables,
  Columns,
  Links,
  Name,
}

const steps = [
  { id: STEP.Tables, label: 'Select Tables', subLabel: '[0] Tables selected' },
  { id: STEP.Columns, label: 'Describe Columns', subLabel: 'Select & describe each column' },
  { id: STEP.Links, label: 'Identify Join Keys', subLabel: 'Add a connection between Tables' },
  { id: STEP.Name, label: 'Datacake Name', subLabel: '' },
]

interface IProps {}

const CreateDatacakeModal: FC<IProps> = observer(() => {
  // const csvTableSetupRef = useRef<TableSetupStepSelectDatabaseCSVRef>(null);

  const { classes } = useStyles()
  const settings = useSettings()
  const app = useApp()

  const [cakeName, setCakeName] = useState('')
  const [instructionsAnalysis, setInstructionsAnalysis] = useState('')
  const [instructionsVisualization, setInstructionsVisualization] = useState('')
  const [stepLabels, setStepLabels] = useState(steps) // function for this state: setStepLabels
  const [activeStep, setActiveStep] = useState(0)
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [localLinks, setLocalLinks] = useState<(ITableLinkColumnLocal | null)[][]>([
    createLocalTableLink(null, null),
  ])

  const [localColumns, setLocalColumns] = useState<ITableLinkColumnLocal[]>([])
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (settings.isCreateDatacakeModalOpen) {
      if (settings.isEditingDatacake) {
        setCakeName(app.cakeName || '')
        const fInstr = async () => {
          const instr: { analysis: string; visualization: string } = await fetchInstructions(
            app.cakeId,
          )
          console.log('setting instructions to ', instr)
          setInstructionsAnalysis(instr.analysis)
          setInstructionsVisualization(instr.visualization)
        }
        console.log('calling fInstr')
        fInstr()
      } else {
        setCakeName('')
        setInstructionsAnalysis('')
        setInstructionsVisualization('')
      }
    }
  }, [settings.isCreateDatacakeModalOpen, settings.isEditingDatacake])

  useEffect(() => {
    if (settings.isCreateDatacakeModalOpen) {
      setActiveStep(STEP.Tables)
      setIsSelectAll(false)
      setLocalLinks([createLocalTableLink(null, null)])
      setLocalColumns([])

      // disable overflow when the open is open
      document.body.style.overflow = 'hidden'
      settings
        .loadTables()
        .then(() => {
          console.log('Data sources are loaded from CreateDatacakeModal')
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else document.body.style.overflow = 'auto'
  }, [settings.isCreateDatacakeModalOpen])

  const checkIfTableDetailsNeedsFetch = () => {
    const areEqual = areArraysEqual(
      settings.initialTables.filter((source) => source.is_selected),
      settings.tables.filter((source) => source.is_selected),
    )

    settings.fetchNeededforTableDetails = !areEqual
  }

  const selectAllColumns = () => {
    settings.sourceTables.forEach((table: ITable) => {
      table.columns.forEach((column: ITableColumn) => {
        if (column.is_selected && !isSelectAll) {
          return
        }

        settings.toggleTableColumn(table.id, column.name)
      })
    })

    setIsSelectAll(!isSelectAll)
  }

  const handleFinish = async () => {
    setLoadingSubmit(true)

    const localLinksFilter = localLinks.filter((link) => link.every(Boolean))

    settings.updateTableLinks(localLinksFilter)
    app.removeOpenModalParam()

    const payload: IDatacake = {
      cake_id: null,
      name: '',
      max_rows: 0,
      recipe: [],
      sample_questions: [],
    }

    if (settings.isEditingDatacake) payload.cake_id = app.cakeId
    payload.name = cakeName
    payload.instructions = {
      analysis: instructionsAnalysis,
      visualization: instructionsVisualization,
    }

    await settings.createRecipe(payload, () => {
      // app.setIsWelcomeModalOpen(false)
      app.updateSources()
      settings.loadDatacakes()

      setStepLabels(steps)
      settings.setIsCreateDatacakeModalOpen(false, false)
    })
  }

  const handleNext = () => {
    if (activeStep === STEP.Tables) {
      if (!settings.hasSelectedTables()) {
        warning('Please select at least one table.')
        return
      }
    }
    if (activeStep === STEP.Columns) {
      if (!settings.hasSelectedColumns()) {
        warning('Please select at least one column.')
        return
      }
    }
    if (activeStep === STEP.Links) {
    }
    if (activeStep == STEP.Name) {
      handleFinish()
        .then(() => {})
        .catch((e) => console.log('Error in saving profile', e))
      return
    }

    if (activeStep === STEP.Tables) {
      setIsSelectAll(false)
      checkIfTableDetailsNeedsFetch()
    }

    const labels = [...stepLabels]
    if (activeStep === STEP.Name) {
      labels[activeStep].subLabel = cakeName ?? steps[activeStep].subLabel
    }

    if (activeStep === STEP.Tables) {
      const tableCount = settings.tables.filter((source) => source.is_selected).length.toString()
      const rightBraketIndex = labels[activeStep].subLabel.indexOf(']')
      labels[activeStep].subLabel =
        steps[activeStep].subLabel.substring(0, 1) +
        tableCount +
        steps[activeStep].subLabel.substring(rightBraketIndex)
    }

    setStepLabels(labels)

    setActiveStep(activeStep + 1)
  }

  // const getStepDescription = () => {
  //   return stepDescription[activeStep];
  // }

  const getTableContent = () => {
    return (
      <Box className="h-[400px] w-full flex-1 p-4">
        <Box className="h-full">
          <Box className="items-left flex flex-col justify-center">
            <Box>Name your Datacake:</Box>
            <TextField
              autoFocus={true}
              fullWidth
              sx={{ color: 'black' }}
              className="w-full"
              value={cakeName}
              onChange={(e) => {
                setCakeName(e.target.value)
              }}
            />
            <Box className="mt-4">Help Cubie understand your data...</Box>
            <TextField
              multiline
              rows="3"
              fullWidth
              className="w-full"
              defaultValue={instructionsAnalysis}
              placeholder="Enter any information, definitions, and instructions to help Cubie best understand and answer questions about your data"
              onChange={(e) => {
                setInstructionsAnalysis(e.target.value)
              }}
            />
            <Box className="mt-4">Tell Cubie how you like your visualizations...</Box>
            <TextField
              multiline
              rows="3"
              fullWidth
              className="w-full"
              value={instructionsVisualization}
              placeholder=""
              onChange={(e) => {
                setInstructionsVisualization(e.target.value)
              }}
              onKeyUp={(e) => {
                if (e.key === 'End' && (e.ctrlKey || e.metaKey)) handleNext()
              }}
            />
            <Box className="ABC h-4"></Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const getStepContent = () => {
    switch (activeStep) {
      case STEP.Tables:
        return <StepSelectTables />
      case STEP.Columns:
        return <StepDescribeColumns />
      case STEP.Links:
        return (
          <StepLinks
            loadingSubmit={loadingSubmit}
            localLinks={localLinks}
            updateLocalLinks={setLocalLinks}
            localColumns={localColumns}
            setLocalColumns={setLocalColumns}
          />
        )
      default:
        return getTableContent()
    }
  }

  const handleManageDataSources = () => {
    settings.setIsCreateDatacakeModalOpen(false)
    settings.setIsDataSourcesModalOpen(true)
  }
  return (
    <>
      <Modal
        isOpen={settings.isCreateDatacakeModalOpen}
        onRequestClose={() => settings.setIsCreateDatacakeModalOpen(false, false)}
        contentLabel={''}
        closeTimeoutMS={700}
        shouldCloseOnEsc={true}
        overlayClassName={
          'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10 px-4'
        }
        className={`static w-full border border-black bg-white md:w-10/12 xl:w-[70%]`}
      >
        <Box className={classes.contentBox}>
          <Button
            disableRipple
            className={classes.close}
            onClick={() => settings.setIsCreateDatacakeModalOpen(false, false)}
          >
            <img src={CLOSE} />
          </Button>
          <Box className="XZA">
            <Stepper activeStep={activeStep} alternativeLabel>
              {stepLabels.map(
                (item: { id: number; label: string; subLabel: string }, index: number) => (
                  <Step key={index} completed={index <= activeStep}>
                    <StepLabel className={classes.customSteps}>
                      <Typography className="leading-tight" fontWeight={'500'}>
                        {item.label}
                      </Typography>
                      {index <= activeStep && (
                        <Typography variant="caption" className="leading-tight" fontWeight={'400'}>
                          {item.subLabel}
                        </Typography>
                      )}
                    </StepLabel>
                  </Step>
                ),
              )}
            </Stepper>
            <Box id="StepperContent" className="mt-2 h-full w-full px-8">
              {getStepContent()}
            </Box>
            <Box className="justify-space-between flex w-full flex-1">
              {/* {activeStep==STEP.Name && <div className='cursor-pointer flex items-center' onClick={handleManageDataSources}>Add/manage data sources</div>} */}
              <Stack
                direction={`${isMobile ? 'column' : 'row'}`}
                gap={3}
                className="mx-3 mb-6 mt-6 w-full lg:mx-8"
              >
                <Box className={`${activeStep !== STEP.Columns && 'hidden'}`}>
                  <Button onClick={selectAllColumns}>
                    <img
                      src={isSelectAll ? DESELECTALL : SELECTALL}
                      width={20}
                      height={20}
                      className="mr-2"
                    />
                    {`${isSelectAll ? 'Deselect All Columns in All Tables' : 'Select All Columns in All Tables'}`}
                  </Button>
                </Box>
                <Box className={`relative flex flex-1 items-center justify-end gap-4`}>
                  {activeStep == STEP.Tables && (
                    <div
                      className="absolute left-5 cursor-pointer"
                      onClick={handleManageDataSources}
                    >
                      Add/manage data sources
                    </div>
                  )}
                  <Button
                    variant="outlined"
                    className={activeStep == STEP.Tables ? 'invisible' : ''}
                    onClick={() => {
                      if (activeStep === STEP.Tables) return

                      if (activeStep === STEP.Links) {
                        settings.setCameBackFromLinks(true)
                      }

                      setActiveStep(activeStep - 1)
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="outlined"
                    className="rounded bg-primary font-bold text-white hover:bg-primary-hover "
                    onClick={() => {
                      handleNext()
                    }}
                  >
                    <Typography
                      variant="button"
                      className=""
                    >{`${activeStep === STEP.Name ? 'Submit config' : 'Next'}`}</Typography>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
})

export default CreateDatacakeModal
