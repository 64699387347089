import { Box } from '@mui/material'
import { makeStyles } from '../../core/utils/theme'
import { useRef, useState } from 'react'
import { QueryState } from '../../stores/Query'
import { post2api } from '../../core/services/code_service'
import { isEndUserView } from '../../core/utils/main'
import useUser from '../../hooks/useUser'

const useStyles = makeStyles()(() => ({
  answer_box: {
    // flex: '1',
    background: 'white',
    padding: '1rem 1rem 1rem 1rem',
    '& div': {
      marginY: '8px',
    },
    '& .tablediv': {
      marginY: '8px',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    '& .iframediv': {
      marginY: '8px',
      maxWidth: '100%',
      background: 'yellow',
    },
    '& p': {
      margin: '8px',
    },
    '& table': {
      margin: '8px auto',
    },
    '& th': {
      border: '1px solid black',
      background: '#ddd',
    },
    '& td': {
      border: '1px solid black',
      color: 'black',
    },
    '& iframe': {
      marginX: 'auto',
      marginY: '20px',
    },
    '& h1': {
      // fontVariant: 'small-caps',
      fontSize: 'larger',
      fontWeight: 'bold',
    },
    '& h2': {
      fontSize: 'large',
      fontWeight: 'bold',
    },
    '& h3': {
      fontWeight: 'bold',
    },
    '& ul': {
      margin: '10px 40px',
      listStyleType: 'disc',
    },
    '& li': {
      marginLeft: '8px',
    },
  },
}))

interface IProp {
  answer: QueryState
  editable?: boolean
}
const Answer: React.FC<IProp> = ({ answer, editable = false }) => {
  const { classes } = useStyles()
  const user = useUser()

  const origHtml = answer.html
    .replaceAll(
      /<iframe(?! dc_dc)(.*)><\/iframe>/g,
      "<div class='w-full flex justify-center'><iframe dc_dc$1></iframe></div>",
    )
    .replaceAll(
      /<table(?! dc_dc)(.*)>([\S\s]*)<\/table>/g,
      "<div class='tablediv'><table dc_dc$1>$2</table></div>",
    )
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newHtml, setNewHtml] = useState<string>(origHtml)
  const ref = useRef<HTMLDivElement | null>(null) // Define correct ref type

  const handleHtmlChange = (e: React.FocusEvent<HTMLDivElement>) => {
    setNewHtml(e.currentTarget.innerHTML)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape' || ((e.metaKey || e.ctrlKey) && e.key === 'Enter')) {
      ref.current?.blur()
    }
  }

  const handleSaveHtml = () => {
    answer.html = newHtml
    post2api('/0.2/save-answer', { query_state: answer })
    setIsEditing(false)
  }

  const handleRevert = () => {
    setNewHtml(origHtml)
    setIsEditing(false)
  }

  return (
    <Box className="h-full w-full">
      <Box className="absolute right-2">
        {editable &&
          (user.isInsider() || !isEndUserView()) &&
          (!isEditing ? (
            <span
              className="cursor-pointer px-2 opacity-40 hover:opacity-100"
              onClick={() => setIsEditing(true)}
            >
              edit
            </span>
          ) : (
            <>
              <span className="cursor-pointer px-2" onClick={handleRevert}>
                revert
              </span>
              <span className="cursor-pointer px-2" onClick={handleSaveHtml}>
                save
              </span>
            </>
          ))}
      </Box>
      <Box className="h-full w-full overflow-y-auto">
        <Box
          ref={ref}
          component="div"
          className={classes.answer_box}
          contentEditable={isEditing}
          suppressContentEditableWarning={true} // Prevent React warning
          onBlur={handleHtmlChange}
          onKeyDown={handleKeyPress}
          dangerouslySetInnerHTML={{ __html: newHtml }}
        />
      </Box>
    </Box>
  )
}
export default Answer
