import { observer } from 'mobx-react'
import { Box } from '@mui/material'
import { FC, useState } from 'react'
import { error, success } from '../../core/services/alerts'

interface IEditableLine {
  isEditable?: boolean
  line: string
  onFinish: (l: string) => any
}

const EditableLine: FC<IEditableLine> = observer(({ isEditable = true, line, onFinish }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [newLine, setNewLine] = useState(line)

  const handleEdit = () => {
    if (isEditable) setIsEditing(true)
    else alert('No editing while Cubie is thinking')
  }

  const handleChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLine(e.target.value)
  }

  const handleDone = () => {
    setIsEditing(false)
    if (line == newLine) return
    try {
      onFinish(newLine)
      success('Value set')
    } catch (e) {
      console.log('Error saving', e)
      error('An error occured: ' + e)
      setNewLine(line)
    }
  }

  const handleKeyDown: React.KeyboardEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' && (e.metaKey || e.ctrlKey)) || e.key == 'Escape') {
      handleDone()
    }
  }

  return (
    <Box className="w-full grow overflow-clip">
      {isEditing ? (
        <textarea
          value={newLine}
          onChange={handleChange}
          onBlur={handleDone}
          onKeyDown={handleKeyDown}
          autoFocus
          rows={newLine.split('\n').length}
          style={{ width: '100%' }}
        />
      ) : (
        <Box
          className="maxWidth:'100%' relative left-[4px] top-[1px] inline grow cursor-pointer"
          onClick={handleEdit}
        >
          {newLine.split('\n').map((a, i) => {
            const n = a.search(/\S/)
            return (
              <div key={i} style={{ marginLeft: `${4 * n}px` }}>
                {a || <span>&nbsp;</span>}
              </div>
            )
          })}
        </Box>
      )}
    </Box>
  )
})

export default EditableLine
