import { Box, Typography, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useEffect, useRef, useState } from 'react'
import { ITableLinkColumnLocal } from '../../../core/types/source_service/ITableLinkColumnLocal'
import {
  areAllLinksComplete,
  createLocalTableLink,
  tableLinksToLocalColumns,
  tableLinksToLocalLinks,
  validateLocalTableLink,
} from '../../../core/utils/table_setup'
import theme, { makeStyles } from '../../../core/utils/theme'
import useSettings from '../../../hooks/useSettings'
import Loader from '../../Misc/Loader'

// Icons
import LEFTARROW from '../../../assets/Svgs/navigation/chevron_left_24px.svg'
import RIGHTARROW from '../../../assets/Svgs/navigation/chevron_right_24px.svg'
import TableJoiningLink from './Components/TableJoiningLink'

const useStyles = makeStyles()((theme: any) => ({
  wrapper: {
    width: '100%',
    marginTop: '0px',
    display: 'grid',
    gridTemplateRows: '90px 55vh max-content',
    paddingBottom: '2rem',
  },
  descriptionContainer: {
    alignSelf: 'flex-end',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '10rem',
    margin: '0 auto',
    flexDirection: 'row',
  },
  fontWeight: {
    fontweight: 300,
  },
  tableOrColumnContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.25rem',
    padding: '0.5rem 0px',
  },

  title: {
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      minWidth: '9rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '6rem',
    },
  },
  lessThanGraterThenSign: {
    width: '2.5rem',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  Boxider: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
  },

  settings: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

interface IProps {
  // totalSteps: number
  // step: number
  // onStepChange: (step: number) => void
  loadingSubmit: boolean
  localLinks: (ITableLinkColumnLocal | null)[][]
  localColumns: ITableLinkColumnLocal[]
  updateLocalLinks: (localLinks: (ITableLinkColumnLocal | null)[][]) => void
  setLocalColumns: (localColumns: ITableLinkColumnLocal[]) => void
}

const StepLinks: FC<IProps> = observer(
  ({ loadingSubmit, localLinks, localColumns, updateLocalLinks, setLocalColumns }: IProps) => {
    const settings = useSettings()
    const linksBox = useRef<HTMLDivElement>(null)
    const { classes } = useStyles()
    const [_links, setLocalLinks] = useState<(ITableLinkColumnLocal | null)[][]>(localLinks)

    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    // const [localColumns, setLocalColumns] = useState<ITableLinkColumnLocal[]>([])

    console.log('localLinks', _links)

    const scrollToBottomLinks = () => {
      // Scroll to the bottom of the links container.
      setTimeout(() => {
        if (linksBox.current != null) {
          linksBox.current.scrollTop = linksBox.current.scrollHeight
        }
      }, 0)
    }

    useEffect(() => {
      if (settings.tables.filter((source) => source.is_selected).length <= 1) {
        console.log('Return as datasource length is equal or less than 1')
        return
      }
      const selectedTablesFromHistory = settings.getSelectedTablesFromHistory()
      console.log('Tables from history', selectedTablesFromHistory)

      const columns = tableLinksToLocalColumns(selectedTablesFromHistory)
      if (columns.length > 0) {
        setLocalColumns(columns)
      }

      const links = tableLinksToLocalLinks(selectedTablesFromHistory, columns)
      console.log('Table links length', links.length)
      if (links.length > 0) {
        links.push(createLocalTableLink(null, null))
        setLocalLinks(links)
        updateLocalLinks(links)
      }
    }, [])

    return (
      <Box className="ABCDE /*rounded*/ h-[300px] h-[400px] w-full flex-1 border border-[#0A191C1F] p-4 lg:h-[600px] xl:h-[400px]">
        {settings.isLoading && <Loader className={classes.loader} />}
        <Box className={`overflow-y-auto ${settings.isLoading ? 'h-[90%]' : 'h-full'}`}>
          <Box className={`${classes.wrapper} h-[400px]`}>
            {loadingSubmit ? (
              <Loader className={classes.loader} />
            ) : !settings.hasSelectedColumnsInMultipleTables() ? (
              <Box>
                There are no joins possible, since there is only one table selected or only one
                table has fields selected.
              </Box>
            ) : (
              <Box ref={linksBox} className={`w-[100%] md:w-full lg:w-[90%] xl:w-full`}>
                <Box className="flex flex-row items-center justify-center">
                  <Typography
                    className={`${classes.title} ${isMobile && 'text-base'} `}
                    variant="h6"
                  >
                    Table / Column
                  </Typography>
                  <Connector />
                  <Typography
                    className={`${classes.title} ${isMobile && 'text-base'} `}
                    variant="h6"
                  >
                    Table / Column
                  </Typography>
                </Box>
                <hr className={classes.Boxider} />

                {_links.map((localLink: (ITableLinkColumnLocal | null)[], index: number) => (
                  <TableJoiningLink
                    key={index}
                    columns={localColumns}
                    localLink={localLink}
                    onColumn1Change={(column1: ITableLinkColumnLocal | null) => {
                      if (!validateLocalTableLink(_links, localLink[1], column1)) {
                        return
                      }

                      const updatedLocalLinks = _links.map(
                        (_link: (ITableLinkColumnLocal | null)[], i: number) => {
                          return index === i ? [column1, _link[1]] : _link
                        },
                      )

                      if (areAllLinksComplete(updatedLocalLinks)) {
                        updatedLocalLinks.push(createLocalTableLink(null, null))
                      }

                      setLocalLinks(updatedLocalLinks)
                      updateLocalLinks(updatedLocalLinks)
                      scrollToBottomLinks()
                    }}
                    onColumn2Change={(column2: ITableLinkColumnLocal | null) => {
                      if (!validateLocalTableLink(_links, localLink[0], column2)) {
                        return
                      }

                      const updatedLocalLinks = _links.map(
                        (_link: (ITableLinkColumnLocal | null)[], i: number) => {
                          return index === i ? [_link[0], column2] : _link
                        },
                      )

                      if (areAllLinksComplete(updatedLocalLinks)) {
                        updatedLocalLinks.push(createLocalTableLink(null, null))
                      }

                      setLocalLinks(updatedLocalLinks)
                      scrollToBottomLinks()
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  },
)

export default StepLinks

export const Connector = () => (
  <Box className={'flex flex-row items-center justify-center'}>
    <img src={LEFTARROW} />
    <img src={RIGHTARROW} />
  </Box>
)
