import { FC } from 'react'
import { makeStyles } from '../../core/utils/theme'
import { Box, Tooltip } from '@mui/material'
import DOMPurify from 'dompurify'

const useStyles = makeStyles()((theme) => ({
  table: {
    fontSize: '14px',
    width: '100%',
    maxWidth: '100%',
    '& th, & td': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
    '& thead': {
      position: 'sticky',
      top: '0',
    },
    '& th': {
      background: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
    '& td': {
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
  },
}))

interface IProps {
  columns: string[]
  data: (string | number)[][]
  rowLimit?: number
}
const DataTable: FC<IProps> = ({ columns, data, rowLimit = -1 }) => {
  const { classes } = useStyles()

  console.log('Row limit', rowLimit)

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {columns.map((key: string, index: number) => (
            <th key={index}>
              {/* {key} */}
              {key.length > 10 ? key.replaceAll('_', ' ') : key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any, index: number) => {
          return (
            <tr key={index}>
              {row.map((value: string | number, index2: number) => (
                <td key={index2}>
                  {DOMPurify.sanitize(String(value)).length > 100 ? (
                    <Tooltip
                      title={<span className="text-sm">{DOMPurify.sanitize(String(value))}</span>}
                    >
                      <Box className="text-left">
                        {DOMPurify.sanitize(String(value)).slice(0, 47)}...
                      </Box>
                    </Tooltip>
                  ) : (
                    DOMPurify.sanitize(String(value))
                  )}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
export default DataTable
