import useUser from '../../hooks/useUser'
import { observer } from 'mobx-react'
import { Box, Grid, MenuItem, Select, Tooltip as SimpleTooltip } from '@mui/material'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { isEndUserView } from '../../core/utils/main'
import ClearIcon from '@mui/icons-material/Clear'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { FC } from 'react'
import { makeStyles } from '../../core/utils/theme'
import FeedbackControl from '../AskCubie/FeedbackControl'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CopyToClipboard from 'react-copy-to-clipboard'
import CodeIcon from '@mui/icons-material/Code'
import { success } from '../../core/services/alerts'
import Answer from '../AskCubie/Answer'
import { QueryState } from '../../stores/Query'

const useStyles = makeStyles()(() => ({
  gridItemContainer: {
    margin: '20px',
    overflow: 'hidden',
    position: 'relative',
    border: '1px solid black',
    width: '608px',
    // height:'430px',
    height: '608px',
    // minHeight:'430px',
    // maxHeight:'608px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dashboardItemControls: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '24px',
    display: 'flex',
    background: '#ffff',
    zIndex: '2',
    opacity: '0%',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: '100%',
    },
    borderBottom: '1px solid grey',
  },
  dashboardItemControlsEndUserView: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '24px',
    display: 'flex',
    background: '#fff0',
    zIndex: '2',
    opacity: '100%',
  },
  dashboardItemControl: {
    height: '80%',
    cursor: 'pointer',
    opacity: '40%',
    '&:hover': {
      opacity: '100%',
    },
  },
  answerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '606px',
    overflow: 'hidden',
    zIndex: '0',
  },
  answerWrapper: {
    overflowY: 'auto',
    textOverflow: 'ellipsis',
    position: 'relative',
    scale: '67%',
    width: '150%',
    minHeight: '150%',
    left: '-150px',
    top: '-150px',
    zIndex: '1',
    // paddingTop:'5px',
    flex: '1 0',
  },
  dashboardItemLowerControls: {
    position: 'absolute',
    bottom: '0px',
    background: '#fff',
    height: '20px',
    width: '100%',
    padding: '0px 2px 0 2px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '2',
    opacity: '0%',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: '100%',
    },
    borderTop: '1px solid grey',
    color: 'black',
  },
}))

// const timestr = (s: string) => {
//   if (!s)
//     return ''
//   return s.slice(0,19).replace('T', '  ')
// }

interface IPropsDashboardItem {
  item: QueryState
  i: number
  numItems: number
  handlers: any
}

const DashboardItem: FC<IPropsDashboardItem> = observer(({ item, i, numItems, handlers }) => {
  const user = useUser()
  const { classes } = useStyles()

  return (
    <>
      <Grid item className={classes.gridItemContainer} key={item.qid}>
        <Box
          className={
            user.isInsider() || !isEndUserView()
              ? classes.dashboardItemControls
              : classes.dashboardItemControlsEndUserView
          }
        >
          {(user.isInsider() || !isEndUserView()) && (
            <Box className="flex h-full flex-col justify-center">
              <SimpleTooltip title="Remove from Dashboard">
                <ClearIcon
                  className={classes.dashboardItemControl}
                  onClick={() => handlers['delete'](item.qid)}
                />
              </SimpleTooltip>
            </Box>
          )}
          <Box className="w-full grow overflow-clip"></Box>
          <Box className="flex gap-[4px] text-xs">
            {(user.isInsider() || !isEndUserView()) && (
              <>
                <Box className="flex h-full flex-col justify-center">
                  <CopyToClipboard
                    text={item.question || ''}
                    onCopy={() => {
                      success('Copied to clipboard')
                    }}
                  >
                    <SimpleTooltip title={item.question}>
                      <HelpOutlineIcon className={classes.dashboardItemControl} />
                    </SimpleTooltip>
                  </CopyToClipboard>
                </Box>
                {item.code && (
                  <Box className="flex h-full flex-col justify-center">
                    <CopyToClipboard
                      text={item.code.join('\n') || ''}
                      onCopy={() => {
                        success('Copied to clipboard')
                      }}
                    >
                      <CodeIcon
                        data-tooltip-id="code_tooltip"
                        className={classes.dashboardItemControl}
                      />
                    </CopyToClipboard>
                    <ReactTooltip id="code_tooltip">
                      {item.code.map((line, i) => (
                        <div key={i}>{line}</div>
                      ))}
                    </ReactTooltip>
                  </Box>
                )}
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        margin: '0',
                        backgroundColor: '#fff', // Set background color of the dropdown
                        color: 'black',
                        '& hover': {
                          backgroundColor: '#fff',
                        }, // Set text color for better visibility
                      },
                    },
                  }}
                  className="appearance-none bg-white"
                  value={(i as number) + 1}
                  onChange={(e) => handlers['reorder'](i + 1, e.target.value as number)}
                >
                  {[...Array(numItems).keys()].map((_, index) => (
                    <MenuItem
                      key={index}
                      className="ABC bg-white hover:bg-blue-300 aria-selected:bg-blue-100 aria-selected:hover:bg-blue-300"
                      value={(index as number) + 1}
                    >
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
            {/* <Tooltip title="Re-run Analysis">
                    <ReplayIcon
                      className={classes.dashboardItemControl}
                      onClick={(e) => rerun(e, item.query_id)}
                    />
                  </Tooltip> */}
            <Box className="flex h-full flex-col justify-center">
              <SimpleTooltip title="Full screen">
                <Box className={classes.dashboardItemControl}>
                  <OpenInFullIcon
                    className={classes.dashboardItemControl}
                    onClick={() => handlers['expand'](i)}
                  />
                </Box>
              </SimpleTooltip>
            </Box>
          </Box>
        </Box>
        <Box className="flex flex-grow flex-col">
          <Box className={classes.answerContainer}>
            <Box className={classes.answerWrapper}>
              <Answer answer={item} editable={false} />
            </Box>
          </Box>
        </Box>
        <Box className={classes.dashboardItemLowerControls}>
          <Box className="flex-column flex h-full justify-end text-xs italic text-black">
            {/* Last run {timestr(item.saved_at)} */}
          </Box>
          <Box className="flex-column flex h-full justify-end text-xs italic text-black">
            <FeedbackControl queryId={item.qid} isDashboard={true} />
          </Box>
        </Box>
      </Grid>
    </>
  )
})

export default DashboardItem
