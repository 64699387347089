import { observer } from 'mobx-react'
import useSettings from '../../hooks/useSettings'
import ContentModal from './ContentModal'
import { Box, Input } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import { APP_NAME } from '../../core/config/main'
import { ITable } from '../../core/types/source_service/ITable'
import { makeStyles } from '../../core/utils/theme'
import checked from '../../assets/checked.svg'
import useApp from '../../hooks/useApp'
import { IDataObject } from '../../core/types/code_service/IDataObject'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  sourceTableContainer: {
    paddingBottom: '1.5rem',
    paddingRight: '0.75rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.25rem 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '0.25rem',
      alignItems: 'flex-start',
    },
  },
  tableName: {
    width: '100%',
    maxWidth: '13rem',
    overflowWrap: 'break-word',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  tableInputContainer: {
    width: '100%',
  },
  tableInput: {
    width: '100%',
    wordWrap: 'break-word',
    border: `1px solid #AAA`,
    background: 'transparent',
    padding: '0.5rem 0.75rem',
    outline: 'none',

    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  columnsContainer: {
    marginTop: '0.5rem',
  },
  rowMain: {
    width: '100%',
    maxWidth: '13rem',
  },
  checkBoxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    wordBreak: 'break-all',
    fontWeight: '400',
  },
  checkBox: {
    minWidth: '20px',
    marginLeft: '0.125rem',
    width: '1rem',
    height: '1rem',
    color: theme.palette.text.primary,
    WebkitAppearance: 'none',
    border: `1px solid ${theme.palette.text.disabled}`,

    '&:checked': {
      background: `url(${checked})`,
      backgroundSize: '100% 100%',
      border: 'none',
    },
  },
}))

const ColumnDescriptionsEditable: FC = observer(() => {
  const { classes } = useStyles()
  const [focusedId, setFocusedId] = useState<string | null>(null)
  const app = useApp()
  const settings = useSettings()

  const extractDescriptions = (objs: IDataObject[]) => {
    return objs.map((x: IDataObject) => {
      return {
        name: x.name,
        description: x.description,
        columns:
          x.columns?.map((c: any) => {
            return { name: c.name, description: c.description }
          }) || [],
      } as ITable
    }) as ITable[]
  }

  const [descriptions, setDescriptions] = useState<ITable[]>(
    extractDescriptions(app.coreDataObjects),
  )

  const handleSubmit = () => {
    settings.saveTableColumnDescriptions(descriptions as [])
    console.log(descriptions)
  }

  const editTableDescription = (tables: ITable[], tid: string, value: string) => {
    for (var t of tables) {
      if (t.id == tid) {
        t.description = value
        setDescriptions(tables)
        return
      }
    }
  }

  const editColumnDescription = (tables: ITable[], tid: string, cname: string, value: string) => {
    for (var t of tables) {
      if (t.id == tid) {
        for (var c of t.columns) {
          if (c.name == cname) {
            c.description = value
            setDescriptions(tables)
            return
          }
        }
      }
    }
  }

  return (
    <Box className="flex h-full w-full flex-col overflow-hidden">
      <Box className="ABCDE flex-0 mx-4">
        Edit descriptions of your tables and columns. This is very important&mdash;the more complete
        the description, the better {APP_NAME} will understand each table/column and how to analyze
        the data.
      </Box>
      <Box className="mx-4 mt-4 flex-1 overflow-auto border border-black bg-white p-4">
        {/* Columns with descriptions */}
        {Object.values(descriptions).map((table: ITable) => (
          <Box key={table.id} className={classes.sourceTableContainer}>
            <Box className={classes.row}>
              <Box className={classes.tableName}>{table.name}</Box>
              <Box className={classes.tableInputContainer}>
                <Input
                  className={classes.tableInput}
                  autoFocus={table.id === focusedId}
                  multiline={true}
                  onFocus={(e) => {
                    e.preventDefault()
                    const val = e.target.value
                    e.target.value = ''
                    e.target.value = val
                    setFocusedId(table.id)
                  }}
                  onBlur={(e) => {
                    e.preventDefault()
                    setFocusedId(null)
                  }}
                  sx={[
                    settings.isTableDescriptionGuessed(table.id) && {
                      fontStyle: 'italic !important',
                      color: () => '#999' + ' !important',
                    },
                    settings.isTableDescriptionEntered(table.id) && {
                      fontStyle: 'normal !important',
                      fontWeight: '600',
                      color: (theme) => theme.palette.text.primary + ' !important',
                    },
                  ]}
                  disableUnderline
                  defaultValue={table.description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    editTableDescription(descriptions, table.id, e.target.value)
                  }}
                />
              </Box>
            </Box>

            <Box className="mt-2">
              {table.columns &&
                table.columns.length > 0 &&
                table.columns.map((column: ITableColumn) => (
                  <Box key={column.name} className={classes.row}>
                    <Box className={classes.rowMain}>
                      <label className={classes.checkBoxLabel}>
                        {/* <input
                            checked={settings.isTableColumnEnabled(table.id, column.name)}
                            type="checkbox"
                            className={classes.checkBox}
                            onChange={() => {
                              settings.toggleTableColumn(table.id, column.name)
                            }}
                          /> */}
                        {column.name}
                      </label>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Input
                        className={classes.tableInput}
                        autoFocus={`${table.id}-${column.name}` === focusedId}
                        multiline={true}
                        onFocus={(e) => {
                          e.preventDefault()
                          const val = e.target.value
                          e.target.value = ''
                          e.target.value = val
                          setFocusedId(`${table.id}-${column.name}`)
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                          setFocusedId(null)
                        }}
                        sx={[
                          settings.isTableColumnDescriptionGuessed(table.id, column.name) && {
                            fontStyle: 'italic',
                            color: (theme) => theme.palette.text.disabled,
                          },
                          settings.isTableColumnDescriptionEntered(table.id, column.name) && {
                            fontStyle: 'normal !important',
                            fontWeight: '600',
                            color: (theme) => theme.palette.text.primary + ' !important',
                          },
                        ]}
                        disableUnderline
                        defaultValue={column.description}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          editColumnDescription(descriptions, table.id, column.name, e.target.value)
                        }}
                      />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box className="flex-0 mx-4 my-4 flex justify-end text-right">
        <Box className="cursor-pointer border border-black px-4" onClick={handleSubmit}>
          Submit
        </Box>
      </Box>
    </Box>
  )
})

const DescriptionsModal: FC = observer(() => {
  const settings = useSettings()

  const handleClose = () => {
    settings.setIsDescriptionsModalOpen(false)
  }

  return (
    <>
      <ContentModal
        open={settings.isDescriptionsModalOpen}
        title="Descriptions"
        onClose={handleClose}
      >
        <ColumnDescriptionsEditable />
      </ContentModal>
    </>
  )
})

export default DescriptionsModal
