import { Box, Menu, MenuItem, alpha } from '@mui/material'
import { makeStyles } from '../../core/utils/theme'
import theme from '../../core/utils/theme'
import React, { FC } from 'react'
import { LOGOUT_URL } from '../../core/config/main'
import useSettings from '../../hooks/useSettings'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'inline',
  },
  circle: {
    width: '40px',
    height: '40px',
    marginRight: '40px',
    marginTop: '6px',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      marginRight: '32px',
      marginTop: '13px',
      width: '36px',
      height: '36px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      width: '33px',
      height: '33px',
      marginRight: '4px',
      marginTop: '3px',
      fontSize: '20px',
    },
  },
}))

interface IProps {
  text: string
}
const MainMenu: FC<IProps> = ({ text }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const settings = useSettings()
  const { classes } = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = (tabIndex: number | string) => {
    if (tabIndex == 'datasrcs') settings.setIsDataSourcesModalOpen(true)
    else if (tabIndex == 'datacakes') settings.setIsDatacakesModalOpen(true)
  }

  const handleLogout = () => {
    window.location.href = LOGOUT_URL
  }

  return (
    <Box className={classes.root}>
      <span onClick={handleClick} style={{ cursor: 'pointer' }}>
        {text}
      </span>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderWidth: 1,
            borderColor: 'black',
            background: 'white',
            // background: '#b1d0f8',
            // marginTop: 1,
            width: 190,
            '& .MuiList-root': {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            },
            '& .MuiMenuItem-root': {
              display: 'flex',
              flex: 1,
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
              },
              '&:hover': {
                backgroundColor: 'black',
                color: 'white',
              },
              '&:active': {
                backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity,
                ),
              },
            },
          },
        }}
      >
        <Box onMouseLeave={() => setAnchorEl(null)}>
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Menu</div>
          <MenuItem className="adminMenuItem" onClick={() => handleClose('datacakes')}>
            Your Datacakes
          </MenuItem>
          <MenuItem onClick={() => handleClose('datasrcs')}>Data Sources</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Box>
      </Menu>
    </Box>
  )
}

export default MainMenu
