import { FC } from 'react'
import { ITableLinkColumnLocal } from '../../../../core/types/source_service/ITableLinkColumnLocal'
import { makeStyles } from '../../../../core/utils/theme'
import { Box } from '@mui/material'
import TableAttributesDropdown from './TableAttributesDropdown'
import { Connector } from '../StepLinks'

interface ITableLinkProps {
  columns: ITableLinkColumnLocal[]
  localLink: (ITableLinkColumnLocal | null)[]
  onColumn1Change: (column1: ITableLinkColumnLocal | null) => void
  onColumn2Change: (column1: ITableLinkColumnLocal | null) => void
}

const useStyle = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.25rem',
    padding: '0.5rem 0px',
  },

  select: {
    width: '45%',
    flexGrow: 1,
    textAlign: 'center',
  },

  localLink: {
    width: '2.5rem',
    textAlign: 'center',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
}))

const TableJoiningLink: FC<ITableLinkProps> = ({
  columns,
  onColumn1Change,
  onColumn2Change,
  // localLink,
}) => {
  const { classes } = useStyle()
  return (
    <Box className={classes.container}>
      <Box className={classes.select}>
        {/** selectedColumn={localLink[0]} */}
        <TableAttributesDropdown columns={columns} onChange={onColumn1Change} />
      </Box>
      <Connector />
      <Box className={classes.select}>
        {/** selectedColumn={localLink[1]} */}
        <TableAttributesDropdown columns={columns} onChange={onColumn2Change} />
      </Box>
    </Box>
  )
}
export default TableJoiningLink
