import { Box, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { makeStyles } from '../../core/utils/theme'
import DataObjectEntries from './DataObjects'
import useApp from '../../hooks/useApp'
import { observer } from 'mobx-react'
import useSettings from '../../hooks/useSettings'
import { isEndUserView } from '../../core/utils/main'
import Logo2 from '../Misc/Logo2'
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import DataSourcesModal from '../DataSources/DataSourcesModal'
import DataSourceManagerModal from '../DataSources/DataSourceManagerModal'
import DatacakesModal from '../Datacakes/Datacakes'
import CreateDatacakeModal from '../CakeBaking/CreateDatacakeModal'
import { LOGIN_URL } from '../../core/config/main'
import MainMenu from './MainMenu'
import DescriptionsModal from '../Modals/DescriptionsModal'
import InstructionsModal from '../Modals/InstructionsModal'
import { LoadingSpinner } from '../Misc/Loader'
import CopyToClipboard from 'react-copy-to-clipboard'
import { success } from '../../core/services/alerts'
import { Tooltip } from 'react-tooltip'
import SearchIcon from '@mui/icons-material/Search'
import ContentModal from '../Modals/ContentModal'
import { ColumnDescriptions } from '../Modals/DataObjectHeadModal'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflow: 'auto',
    width: '325px',
    maxWidth: '325px',
    minWidth: '325px',
    padding: '20px 20px 0 20px',
    borderRight: '1px solid grey',
    position: 'relative',
    zIndex: 1,
  },
  root2: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    position: 'relative',
    zIndex: 1,
  },
  logoBloc: {
    position: 'relative',
    height: '50px',
    width: '100%',
    marginBottom: '10px',
  },
  logo: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '50px',
    height: '50px',
  },
  userBloc: {
    position: 'absolute',
    top: '0px',
    left: '50px',
    right: '0px',
    bottom: '0px',
    textAlign: 'right',
  },
  email: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '0 0 0 15px',
  },
  logoutButton: {
    visibility: 'hidden',
    cursor: 'pointer',
    height: '14px',
    width: '14px',
    marginRight: '3px',
  },
  spacer: {
    height: '20px',
  },
  hr: {
    height: '0px',
    borderBottom: '1px grey solid',
    margin: '10px 0',
    flex: '0',
  },
  navBloc: {
    flex: '0',
  },
  dataBloc: {
    margin: '4px 0',
  },
  modelRoot: {
    paddingLeft: '0.25rem',
    paddingTop: '4px',
    zIndex: '1',
  },
  modelName: {
    display: 'block',
    whiteSpace: 'nowrap', // truncate text
    // overflow: 'hidden', // // truncate text
    textOverflow: 'ellipsis',
    maxWidth: '100%', // or set a specific width according to your layout
    fontSize: '14px',
  },
}))

const LeftSidebar: FC = observer(() => {
  const { classes } = useStyles()

  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const email = user.userEmail || ''

  const [isSourceTableClosed, setIsSourceTableClosed] = useState(false)
  const [isEditTooltipOpen, setIsEditTooltipOpen] = useState(false)
  const [isColumnSearchOpen, setIsColumnSearchOpen] = useState(false)

  const navigate = useNavigate()

  const navClick = (slug: string) => {
    app.setIsSidebarOpen(true)
    navigate(isEndUserView() ? `/${slug}?cakeId=${app.cakeId}` : `/${slug}`)
    setTimeout(() => {
      app.setIsSidebarOpen(false)
    }, 500)
  }

  const isDashboard = () => {
    return window.location.pathname.includes('dashboard')
  }
  const isAskCubie = () => {
    return window.location.pathname.includes('ask')
  }

  useEffect(() => {
    if (!app.cakeId) setIsSourceTableClosed(true)
    setSourceTabClass()
    if (app.coreDataObjects.length === 0) return
  }, [isSourceTableClosed, app.coreDataObjects])

  function setSourceTabClass() {
    const elt = document.getElementById('srcTables')
    if (elt && elt.classList.contains('-translate-x-full') != isSourceTableClosed)
      elt.classList.toggle('-translate-x-full')
  }

  return (
    <>
      {user.isAuthenticated() && !isEndUserView() && <DatacakesModal />}
      {user.isAuthenticated() && !isEndUserView() && <CreateDatacakeModal />}
      {user.isAuthenticated() && !isEndUserView() && <DataSourcesModal />}
      {user.isAuthenticated() && !isEndUserView() && <DataSourceManagerModal />}
      {user.isAuthenticated() && !isEndUserView() && app.cakeId && <DescriptionsModal />}
      {user.isAuthenticated() && !isEndUserView() && app.cakeId && <InstructionsModal />}
      <Box className={classes.root}>
        <Box className={classes.root2}>
          <Box className={classes.logoBloc}>
            <Box className={classes.logo}>
              <Logo2 />
            </Box>
            <Box className={classes.userBloc}>
              {user.isAuthenticated() && user.userEmail ? (
                <>
                  <Typography
                    variant="caption"
                    style={{ fontFamily: 'helvetica', fontWeight: 'normal' }}
                  >
                    <span
                      onClick={() => {
                        console.log(app.queryState)
                        console.log(app.dashboard)
                      }}
                    >
                      Logged
                    </span>{' '}
                    in as:
                  </Typography>
                  <br />
                  <div
                    className={classes.email}
                    style={{ textAlign: 'right', fontWeight: '500' }}
                    // onMouseOver={()=>{const e = document.getElementById('logoutButton'); if (e) e.style.visibility = 'visible';}}
                    // onMouseOut={()=>{const e = document.getElementById('logoutButton'); if (e) e.style.visibility = 'hidden';}}
                  >
                    {isEndUserView() ? email : <MainMenu text={email} />}
                  </div>
                </>
              ) : (
                <>
                  <Typography
                    variant="caption"
                    style={{ fontFamily: 'helvetica', fontWeight: 'normal' }}
                  >
                    Not logged in
                  </Typography>
                  <br />
                  <div className={classes.email} style={{ textAlign: 'right', fontWeight: '500' }}>
                    <span
                      className="cursor-pointer underline"
                      onClick={() => {
                        window.location.href = LOGIN_URL
                      }}
                    >
                      Login
                    </span>
                  </div>
                </>
              )}
            </Box>
          </Box>
          <div className={classes.hr} />
          <Box className={classes.navBloc}>
            <Box className="flex justify-around">
              <div
                className={`cursor-pointer px-[5px] py-0 ${isDashboard() && 'font-bold'}`}
                onClick={() => {
                  navClick('dashboard')
                }}
              >
                Dashboard
              </div>
              <div
                className={`cursor-pointer px-[5px] py-0 ${isAskCubie() && 'font-bold'}`}
                onClick={() => {
                  navClick('ask')
                }}
              >
                Ask Cubie
              </div>
            </Box>
          </Box>
          <div className={classes.hr} />
          <Box className={classes.dataBloc}>
            <Box
              className="align-center z-1 mb-1 flex h-5 justify-between bg-black px-1 text-sm font-bold text-white"
              style={{ fontFamily: 'helvetica' }}
            >
              <Box className="align-center flex h-full">
                {user.isAuthenticated() && !isEndUserView() && app.cakeId ? (
                  <>
                    <span
                      className="cursor-pointer"
                      data-tooltip-id="edit-tooltip"
                      onClick={() => setIsEditTooltipOpen(true)}
                    >
                      Source Data
                    </span>
                    <Tooltip
                      id={`edit-tooltip`}
                      openOnClick={true}
                      clickable={true}
                      opacity={1}
                      globalCloseEvents={{
                        escape: true,
                        resize: true,
                        scroll: true,
                        clickOutsideAnchor: true,
                      }}
                      className="border"
                      isOpen={isEditTooltipOpen}
                      setIsOpen={setIsEditTooltipOpen}
                    >
                      <Box className="flex w-[120px] flex-col justify-between gap-2">
                        <Box
                          onClick={() => {
                            setIsEditTooltipOpen(false)
                            settings.setIsDescriptionsModalOpen(true)
                          }}
                          className="cursor-pointer text-xs text-gray-300 hover:text-white"
                        >
                          Edit Descriptions
                        </Box>
                        <Box
                          onClick={() => {
                            setIsEditTooltipOpen(false)
                            settings.setIsInstructionsModalOpen(true)
                          }}
                          className="cursor-pointer text-xs text-gray-300 hover:text-white"
                        >
                          Edit Instructions
                        </Box>
                        <Box
                          onClick={() => {
                            setIsEditTooltipOpen(false)
                            settings.setIsCreateDatacakeModalOpen(true, true)
                          }}
                          className="cursor-pointer text-xs text-gray-300 hover:text-white"
                        >
                          Edit Recipe
                        </Box>
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  'Source Data'
                )}
              </Box>
              <Box className="flex h-full flex-col justify-center">
                {isColumnSearchOpen && (
                  <ContentModal
                    open={isColumnSearchOpen}
                    onClose={() => {
                      setIsColumnSearchOpen(false)
                    }}
                    title=""
                    style={{ content: { height: '70%' } }}
                  >
                    <ColumnDescriptions sources={app.coreDataObjects} />
                  </ContentModal>
                )}
                <SearchIcon
                  className="cursor-pointer opacity-50 hover:opacity-80"
                  sx={{ height: '18px' }}
                  onClick={() => setIsColumnSearchOpen(true)}
                />
              </Box>
            </Box>
            {app.isInitializing ? (
              <Box className="my-4 flex w-full justify-center">
                <LoadingSpinner className="h-8 w-8" />
              </Box>
            ) : (
              <DataObjectEntries />
            )}
          </Box>
          {user.userEmail && user.userEmail.includes('@datacakes.ai') && !app.isInitializing && (
            <Box className={classes.dataBloc}>
              <Box
                className="z-1 mb-1 h-5 bg-black pl-1 text-sm font-bold text-white"
                style={{ fontFamily: 'helvetica' }}
              >
                Models
              </Box>
              <Box className={classes.modelRoot}>
                <Box className={classes.modelName}>
                  &#x2325; attribution_2024_08_20
                  <br />
                </Box>
              </Box>
              <Box className={classes.modelRoot}>
                <Box className={classes.modelName}>
                  &#x2325; forecaster_2024_02_24
                  <br />
                </Box>
              </Box>
              <Box className={classes.modelRoot}>
                <Box className={classes.modelName}>
                  &#x2325; anomaly_alert_2024_06_02
                  <br />
                </Box>
              </Box>
            </Box>
          )}
          <div className={classes.hr} />
          {app.sessionId && (
            <Box className="flex-1 text-xs">
              <Box>
                <CopyToClipboard
                  text={app.sessionId}
                  onCopy={() => {
                    success('Copied to clipboard')
                  }}
                >
                  <span className="cursor-pointer">Session {app.sessionId}</span>
                </CopyToClipboard>
              </Box>
              {app.queryState.qid && (
                <Box className="ml-6 py-[0.125rem]">
                  <CopyToClipboard
                    text={`${app.sessionId}-${app.queryState.qid}`}
                    onCopy={() => {
                      success('Copied to clipboard: ' + `${app.sessionId}-${app.queryState.qid}`)
                    }}
                  >
                    <span className="cursor-pointer">Q. </span>
                  </CopyToClipboard>
                  <span className="ml-1">
                    {app.queryState.question.length > 100
                      ? app.queryState.question.slice(0, 100) + '...'
                      : app.queryState.question}
                  </span>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box className="flex-0 py-2 text-center text-xs text-black">
          <Box>
            <a
              className="text-xs text-black"
              target="_blank"
              href="https://www.datacakes.ai/privacy"
            >
              Privacy policy
            </a>
          </Box>
          <Box>&copy; {new Date().getFullYear()} Datacakes Inc.</Box>
        </Box>
      </Box>
    </>
  )
})

export default LeftSidebar
